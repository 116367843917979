import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '../../../utils copy/@reduxjs/toolkit';
import set from 'lodash/set';
import { CoinStudentDetailInterface, StudentDetailListInterface, TeacherAddInterface, TeacherDetailListInterface, TeacherState } from '../types';
export const initialState: TeacherState = {
  loading: false,
  pageSize: 10,
  totalRow: 0,
  search: '',
  pageNo: 1,
  editIndex: 0,
  isadd: false,
  Form: {
    id: '',
    status: 0,
    title: '',
    isParent: 1,
    parentList: []
  },
  List: [],
  parentList: [],
  childList: [],
  teacherAddList: [],
  teacherAddForm: {
    id: '',
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    addCoins: 0,
    comment: '',
    mobileNumber: 0,
    address: '',
    zip: 0,
    city: '',
    state: '',
    coins: 0,
    class: '',
    coinsUsed: 0,
    createdAt: '',
    monthCoinsUsed: 0,
    studentCount: 0,
    updatedAt: '',
    section: ''
  },
  teacherloading: false,
  teacherisadd: false,
  teacherpageNo: 0,
  teacherpageSize: 0,
  teachertotalRow: 0,
  teachersearch: '',
  TeacherList: [],
  teacherDetailloading: false,
  teacherDetailisadd: false,
  teacherDetailpageNo: 0,
  teacherDetailpageSize: 0,
  teacherDetailtotalRow: 0,
  teacherDetailsearch: '',
  TeacherDetailList: [],
  studentAddCoinsForm: {
    id: '',
    coins: 0,
    comment: ''
  },
  StudentDetailList: [],
  StudentDetailpageNo: 0,
  StudentDetailpageSize: 0,
  StudentDetailtotalRow: 0,
  CoinStudentDetailList: [],
  CoinStudentDetailpageNo: 0,
  CoinStudentDetailpageSize: 0,
  CoinStudentDetailtotalRow: 0
};

export const useClinicSlice = createSlice({
  name: 'teacherState',
  initialState,
  reducers: {
    doAddCoinBYId: (state, action: PayloadAction<{ id: string; callback: any }>) => {},
    dogetByIdTeacher: (state, action: PayloadAction<{ id: any; callback: any }>) => {},
    dogetByIdStudent: (state, action: PayloadAction<{ id: any; callback: any }>) => {},
    dogetByIdCoinDetailStudent: (state, action: PayloadAction<{ id: any; callback: any }>) => {},
    clearCoinsFrom: (state) => {
      state.studentAddCoinsForm.id = '';
      state.studentAddCoinsForm.coins = 0;
      state.studentAddCoinsForm.comment = '';
    },
    doGetTeacherList: (state: any) => {},
    doGetTeacherDetailList: (state: any, action: PayloadAction<string>) => {},
    updateTeacherFormValue: (state, action: PayloadAction<{ key: string; value: any }>) => {
      set(state, `teacherAddForm.${action.payload.key}`, action.payload.value);
    },
    setTeacherList: (state, action: PayloadAction<Array<TeacherAddInterface>>) => {
      state.TeacherList = action.payload;
    },
    doAddTeacher: (state, action: PayloadAction<{ callback: () => void }>) => {},
    doUpdateCategoryStatus: (state, action: PayloadAction<string>) => {
      console.log('Yo Reached at UserAdded');
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setparentList: (state, action: PayloadAction<[]>) => {
      state.parentList = action.payload;
    },
    setchildList: (state, action: PayloadAction<[]>) => {
      state.childList = action.payload;
    },

    setTeacherLoading: (state, action: PayloadAction<boolean>) => {
      state.teacherloading = action.payload;
    },
    setTeacherPageSize: (state, action: PayloadAction<number>) => {
      state.teacherpageSize = action.payload;
    },
    setTeacherPageNo: (state, action: PayloadAction<number>) => {
      state.teacherpageNo = action.payload;
    },
    setTeacherTotalRow: (state, action: PayloadAction<number>) => {
      state.teachertotalRow = action.payload;
    },
    setTeacherSearch: (state, action: PayloadAction<string>) => {
      state.teachersearch = action.payload;
    },
    setTeacherDetailLoading: (state, action: PayloadAction<boolean>) => {
      state.teacherDetailloading = action.payload;
    },
    setTeacherDetailPageSize: (state, action: PayloadAction<number>) => {
      state.teacherDetailpageSize = action.payload;
    },
    setTeacherDetailPageNo: (state, action: PayloadAction<number>) => {
      state.teacherDetailpageNo = action.payload;
    },
    setTeacherDetailTotalRow: (state, action: PayloadAction<number>) => {
      state.teacherDetailtotalRow = action.payload;
    },
    setTeacherDetailSearch: (state, action: PayloadAction<string>) => {
      state.teacherDetailsearch = action.payload;
    },
    setTeacherDetailList: (state, action: PayloadAction<Array<TeacherDetailListInterface>>) => {
      state.TeacherDetailList = action.payload;
    },
    clearTeacherDetailList: (state) => {
      state.TeacherDetailList = [];
    },
    setStudentDetailPageSize: (state, action: PayloadAction<number>) => {
      state.StudentDetailpageSize = action.payload;
    },
    setStudentDetailPageNo: (state, action: PayloadAction<number>) => {
      state.StudentDetailpageNo = action.payload;
    },
    setStudentDetailTotalRow: (state, action: PayloadAction<number>) => {
      state.StudentDetailtotalRow = action.payload;
    },
    clearStudentDetailList: (state) => {
      state.StudentDetailList = [];
    },
    setStudentDetailList: (state, action: PayloadAction<Array<StudentDetailListInterface>>) => {
      state.StudentDetailList = action.payload;
    },


    setCoinStudentDetailPageSize: (state, action: PayloadAction<number>) => {
      state.CoinStudentDetailpageSize = action.payload;
    },
    setCoinStudentDetailPageNo: (state, action: PayloadAction<number>) => {
      state.CoinStudentDetailpageNo = action.payload;
    },
    setCoinStudentDetailTotalRow: (state, action: PayloadAction<number>) => {
      state.CoinStudentDetailtotalRow = action.payload;
    },
    clearCoinStudentDetailList: (state) => {
      state.CoinStudentDetailList = [];
    },
    setCoinStudentDetailList: (state, action: PayloadAction<Array<CoinStudentDetailInterface>>) => {
      state.CoinStudentDetailList = action.payload;
    },
    SelectedParent: (state, action: PayloadAction<Array<string>>) => {
      state.Form.parentList = action.payload;
    },
    updateStudentAddCoinsFormValue: (state, action: PayloadAction<{ key: string; value: any }>) => {
      set(state, `studentAddCoinsForm.${action.payload.key}`, action.payload.value);
    },
    doGetTeacher: (state) => {},
    doGetChildList: (state) => {},
    setPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },
    setPageNo: (state, action: PayloadAction<number>) => {
      state.pageNo = action.payload;
    },
    setTotalRow: (state, action: PayloadAction<number>) => {
      state.totalRow = action.payload;
    },
    setSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
    },
    updateFormValue: (state, action: PayloadAction<{ key: string; value: any }>) => {
      console.log('action.payload.key,action.payload.value', action.payload.key, action.payload.value);
      set(state, `Form.${action.payload.key}`, action.payload.value);
    },

    doUpdate: (state, action: PayloadAction<{ callback: () => void }>) => {},
    dogetDataForEdit: (state, action: PayloadAction<string>) => {},
    getDataForEdit: (state, action: PayloadAction<TeacherAddInterface>) => {
      state.teacherAddForm = action.payload;
      state.teacherAddForm.comment = '';
    },
    setList: (state, action: PayloadAction<Array<TeacherAddInterface>>) => {
      state.teacherAddList = action.payload;
    },
    doTeacherDelete: (state: any, action: PayloadAction<{ id: string; callback: () => void }>) => {},

    clearFrom: (state) => {
      state.teacherAddForm.id = '';
      state.teacherAddForm.firstName = '';
      state.teacherAddForm.lastName = '';
      state.teacherAddForm.email = '';
      state.teacherAddForm.mobileNumber = 0;
      state.teacherAddForm.address = '';
      state.teacherAddForm.zip = 0;
      state.teacherAddForm.city = '';
      state.teacherAddForm.state = '';
      state.teacherAddForm.coins = 0;
      state.teacherAddForm.password = '';
      state.teacherAddForm.addCoins = 0;
      state.teacherAddForm.class = '';
      state.teacherAddForm.section = '';
      state.teacherAddForm.coinsUsed = 0;
      state.teacherAddForm.monthCoinsUsed = 0;
      state.teacherAddForm.studentCount = 0;
    }
  }
});

export const { reducer, actions, name: sliceKey } = useClinicSlice;
