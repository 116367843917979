import { call, delay, put, select, takeLatest } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';

import { actions } from './slice';

import { openSnackbar } from 'store/reducers/snackbar';
import { SelectPageNo, SelectSearch, selectCategoryFormData } from './selector';
import { CategoryInterface } from '../types';
import { DeleteCategoryNewRequest, GetCategoryNewEditRequest, GetCategorylistRequest, SaveCategoryRequest, UpdatedCategoryRequest } from 'utils copy/request';
import CatchBlockFunction from 'hooks/CatchBlockFunction';

export function* CategoryAddRequest(action: { payload: { callback: any } }) {
  yield delay(500);

  const form: CategoryInterface = yield select(selectCategoryFormData);

  try {
    const response: AxiosResponse = yield call(SaveCategoryRequest, form);
    if (response.data.status == false) {
      // toast.error(response.data.message);
      return;
    }
    yield put(
      openSnackbar({
        open: true,
        message: response.data.message,
        variant: 'alert',
        alert: {
          color: 'success'
        },
        close: false
      })
    );
    yield call(action?.payload?.callback());
  } catch (error: any) {
     CatchBlockFunction(error);
  }
}
export function* CategoryUpdatedRequest(action: { payload: { callback: any } }) {
  yield delay(500);

  const form: CategoryInterface = yield select(selectCategoryFormData);
  try {
    const response: AxiosResponse = yield call(UpdatedCategoryRequest, form);
    if (response.data.status == false) {
      // toast.error(response.data.message);
      return;
    }
    yield put(
      openSnackbar({
        open: true,
        message: response.data.message,
        variant: 'alert',
        alert: {
          color: 'success'
        },
        close: false
      })
    );
    yield call(action?.payload?.callback());
  } catch (error: any) {
     CatchBlockFunction(error);
  }
}
export function* getCategoryListRequest() {
  yield delay(500);

  try {
    const pageNo: number = yield select(SelectPageNo);
    // const pageSize: number = yield select(SelectPageSize);
    const searchText: string = yield select(SelectSearch);
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(
      GetCategorylistRequest,
      `?pageNo=${pageNo}&pageSize=${10000}${searchText.length > 0 ? '&search=' + searchText : ''}`
    );
    yield put(actions.setLoading(false));
    if (response.data.status == false) {
      // toast.error(response.data.message);
      return;
    }
    yield put(actions.setTotalRow(response.data.data.meta ? response.data.data.meta.total : 0));
    console.log(response.data.data, 'getCategoryListRequest');
    yield put(actions.setCategoryList(response.data.data[0].results));

  } catch (error: any) {
       CatchBlockFunction(error);
  }
}
export function* deleteAnnouncement(action: { payload: string }) {
  yield delay(500);

  try {
    const response: AxiosResponse = yield call(DeleteCategoryNewRequest, action.payload);
    console.log(response);

    yield put(actions.doGetCategoryList());
  } catch (error: any) {
      CatchBlockFunction(error);
  }
}
export function* getEditRequest(action: { payload: string }) {
  yield delay(500);

  try {
    const response: AxiosResponse = yield call(GetCategoryNewEditRequest, action.payload);
    if (response.data.status == false) {
      return
    }
    yield put(actions.setCategoryForm(response.data.data[0]));
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* authRepoSagaCategory() {
  yield takeLatest(actions.NewCategoryAdded, CategoryAddRequest);
  yield takeLatest(actions.UpdatedCategoryAdded, CategoryUpdatedRequest);
  yield takeLatest(actions.doGetCategoryList, getCategoryListRequest);
  yield takeLatest(actions.CategoryDelete, deleteAnnouncement);
  yield takeLatest(actions.dogetDataForEdit, getEditRequest);
}

// questionAddRequestMain
