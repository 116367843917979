import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '../../../utils copy/@reduxjs/toolkit';
import { FilterInterface, FilterItem, StudentAddInterface, StudentDetailsInterface, StudentState, StudentType } from '../types';
import set from 'lodash/set';
export const initialState: StudentState = {
  loading: false,
  pageSize: 10,
  totalRow: 0,
  search: '',
  pageNo: 1,
  editIndex: 0,
  isadd: false,
  Form: {
    id: '',
    status: 0,
    title: '',
    items: [],
    itemTitle: ''
  },
  List: [],
  activeList: [],
  studentAddList: [],
  studentAddForm: {
    id: '',
    firstName: '',
    lastName: '',
    email: '',
    mobileNumber: '',
    address: '',
    zip: 0,
    city: '',
    state: '',
    coins: 0,
    password: '',
    section: '',
    class: '',
    coinsEarned: 0,
    coinsUsed: 0,
    teacherFirstName: '',
    teacherLastName: '',
    addCoins: 0,
    comment: ''
  },
  studentAddCoinsForm: { id: '', coins: 0, comment: '' },
  StudentDetails: [],
  detailsPageNo: 1,
  detailsPageSize: 10,
  detailsTotalRow: 0,
  studenttype: StudentType.ALL_STUDENT
};

export const useClinicSlice = createSlice({
  name: 'studentState',
  initialState,
  reducers: {
    doUpdateFilterStatus: (state, action: PayloadAction<string>) => {},
    setLoading: (state: { loading: boolean }, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },

    setPageSize: (state: { pageSize: number }, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },
    setPageNo: (state: { pageNo: number }, action: PayloadAction<number>) => {
      state.pageNo = action.payload;
    },
    setTotalRow: (state: { totalRow: number }, action: PayloadAction<number>) => {
      state.totalRow = action.payload;
    },
    setSearch: (state: { search: string }, action: PayloadAction<string>) => {
      state.search = action.payload;
    },
    updateFormValue: (state: any, action: PayloadAction<{ key: string; value: any }>) => {
      console.log('action.payload.key,action.payload.value', action.payload.key, action.payload.value);
      set(state, `Form.${action.payload.key}`, action.payload.value);
    },
    doGetList: (state: any) => {},
    doGetActiveList: (state: any) => {},
    doAdd: (state: any, action: PayloadAction<{ callback: () => void }>) => {},
    doStudentUpdate: (state: any, action: PayloadAction<{ callback: () => void }>) => {},
    doStudentEdit: (state: any, action: PayloadAction<string>) => {},
    setDataInForm: (state, action: PayloadAction<StudentAddInterface>) => {
      state.studentAddForm = action.payload;
      state.studentAddForm.comment = '';
    },
    setStudenttype: (state, action: PayloadAction<StudentType>) => {
      state.studenttype = action.payload;
    },
    setList: (state, action: PayloadAction<Array<StudentAddInterface>>) => {
      state.studentAddList = action.payload;
    },
    setActiveList: (state, action: PayloadAction<Array<FilterInterface>>) => {
      state.activeList = action.payload;
    },
    doStudentDelete: (state: any, action: PayloadAction<{ id: string; callback: () => void }>) => {},
    addItemList: (state, action: PayloadAction<FilterItem>) => {
      state.Form.items.push(action.payload);
      state.Form.itemTitle = '';
    },
    deleteItemList: (state, action: PayloadAction<number>) => {
      state.Form.items.splice(action.payload, 1);
    },
    clearFrom: (state) => {
      state.studentAddForm.id = '';
      state.studentAddForm.firstName = '';
      state.studentAddForm.lastName = '';
      state.studentAddForm.email = '';
      state.studentAddForm.mobileNumber = '';
      state.studentAddForm.address = '';
      state.studentAddForm.zip = 0;
      state.studentAddForm.city = '';
      state.studentAddForm.state = '';
      state.studentAddForm.coins = 0;
      state.studentAddForm.password = '';
      state.studentAddForm.class = '';
      state.studentAddForm.section = '';
    },
    updateStudentFormValue: (state, action: PayloadAction<{ key: string; value: any }>) => {
      set(state, `studentAddForm.${action.payload.key}`, action.payload.value);
    },
    doAddStudent: (state, action: PayloadAction<{ callback: () => void }>) => {},
    doGetStudent: (state) => {},

    //add coin section
    updateStudentAddCoinsFormValue: (state, action: PayloadAction<{ key: string; value: any }>) => {
      set(state, `studentAddCoinsForm.${action.payload.key}`, action.payload.value);
    },
    clearStudentDetails: (state) => {
      state.StudentDetails = [];
    },
    doAddCoinBYId: (state, action: PayloadAction<{ id: string; callback: any }>) => {},
    doGetStudentDetails: (state, action: PayloadAction<{ id: string; callback: any }>) => {},
    clearCoinsFrom: (state) => {
      state.studentAddCoinsForm.id = '';
      state.studentAddCoinsForm.coins = 0;
      state.studentAddCoinsForm.comment = '';
    },
    setDetailsPageSize: (state: { detailsPageSize: number }, action: PayloadAction<number>) => {
      state.detailsPageSize = action.payload;
    },
    setDetailsPageNo: (state: { detailsPageNo: number }, action: PayloadAction<number>) => {
      state.detailsPageNo = action.payload;
    },
    setDetailsTotalRow: (state: { detailsTotalRow: number }, action: PayloadAction<number>) => {
      state.detailsTotalRow = action.payload;
    },
    setDetailsList: (state, action: PayloadAction<Array<StudentDetailsInterface>>) => {
      state.StudentDetails = action.payload;
    }
  }
});

export const { reducer, actions, name: sliceKey } = useClinicSlice;
