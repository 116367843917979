import { call, delay, put, select, takeLatest } from 'redux-saga/effects';
import { actions } from './slice';
import { openSnackbar } from 'store/reducers/snackbar';
import { AxiosResponse } from 'axios';

import {
  doGetAddToCartList,
  doProductBooking,
  doAddToCart,
  doGetStudentEdit,
  doDeleteStudent,
  doDeleteFromCart,
  GetProductlist,
  doUpdateQuentiy
} from 'utils copy/request';

import CatchBlockFunction from 'hooks/CatchBlockFunction';
import { SelectPageNo, SelectSearch } from './selector';
export function* getProductListRequest() {
  yield delay(500);

  try {
    const pageNo: number = yield select(SelectPageNo);
    const searchText: string = yield select(SelectSearch);
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(
      GetProductlist,
      `?pageNo=${pageNo}&pageSize=${10000}${searchText.length > 0 ? '&search=' + searchText : ''}`
    );
    yield put(actions.setLoading(false));
    if (response.data.status == false) {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'success'
          },
          close: false
        })
      );
      return;
    }
    yield put(actions.setTotalRow(response.data.data.meta ? response.data.data.meta.total : 0));
    yield put(actions.setProductList(response.data.data[0].results));
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* doAddToCartRequest(action: { payload: { id: string; callback: any } }) {
  yield delay(500);

  const data = { productId: action.payload.id, quantity: 1 };
  try {
    const response: AxiosResponse = yield call(doAddToCart, data);
    if (response.data.status == false) {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true
        })
      );

      return;
    }
    yield put(
      openSnackbar({
        open: true,
        message: response.data.message,
        variant: 'alert',
        alert: {
          color: 'success'
        },
        close: true
      })
    );

    yield call(action?.payload?.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* doGetAllAddToCartRequest(action: { payload: any }) {
  yield delay(500);

  try {
    const response: AxiosResponse = yield call(doGetAddToCartList);
    yield put(actions.setLoading(false));
    if (response.data.status == false) {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true
        })
      );
      return;
    }

    yield put(actions.setSelectedProductList(response.data.data[0].carts));
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* doProductBookingRequest(action: { payload: any }) {
  yield delay(500);

  try {
    const response: AxiosResponse = yield call(doProductBooking);
    if (response.data.status == false) {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true
        })
      );

      return;
    }
    yield put(
      openSnackbar({
        open: true,
        message: response.data.message,
        variant: 'alert',
        alert: {
          color: 'success'
        },
        close: true
      })
    );

    yield call(action?.payload?.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* doGetEditRequest(action: { payload: string }) {
  yield delay(500);
  try {
    const response: AxiosResponse = yield call(doGetStudentEdit, action.payload);
    if (response.data.status == false) {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true
        })
      );
      return;
    }
    yield put(actions.setDataInForm(response.data.data[0]));
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}

export function* deleteFilterRequest(action: { payload: { id: string; callback: any } }) {
  yield delay(500);
  if (action.payload.id.length === 0) {
    yield put(
      openSnackbar({
        open: true,
        message: 'Select Story to delete',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      })
    );
    return;
  }

  try {
    const response: AxiosResponse = yield call(doDeleteStudent, action.payload.id);
    if (response.data.status == false) {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true
        })
      );

      return;
    }
    yield put(
      openSnackbar({
        open: true,
        message: response.data.message,
        variant: 'alert',
        alert: {
          color: 'success'
        },
        close: true
      })
    );
    yield call(action?.payload?.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* removeCartProductRequest(action: { payload: any }) {
  yield delay(500);
  try {
    const response: AxiosResponse = yield call(doDeleteFromCart, action.payload);
    if (response.data.status == false) {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true
        })
      );

      return;
    }
    yield put(
      openSnackbar({
        open: true,
        message: response.data.message,
        variant: 'alert',
        alert: {
          color: 'success'
        },
        close: true
      })
    );

    yield put(actions.doGetAddToCartList());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* doUpdateCartQuentityRequest(action: { payload: { id: string; quentity:any; callback: any } }) {
  yield delay(500);

  const data = { quantity: action.payload.quentity, cartId: action.payload.id };

  try {
    const response: AxiosResponse = yield call(doUpdateQuentiy, data);
    if (response.data.status == false) {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true
        })
      );

      return;
    }
    yield put(
      openSnackbar({
        open: true,
        message: response.data.message,
        variant: 'alert',
        alert: {
          color: 'success'
        },
        close: true
      })
    );

    yield call(action?.payload?.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* ProductCartRepoSaga() {
  yield takeLatest(actions.doGetProductList, getProductListRequest);
  yield takeLatest(actions.doAddToCartProduct, doAddToCartRequest);
  yield takeLatest(actions.doGetAddToCartList, doGetAllAddToCartRequest);
  yield takeLatest(actions.doStudentEdit, doGetEditRequest);
  yield takeLatest(actions.doProductBooking, doProductBookingRequest);
  yield takeLatest(actions.doStudentDelete, deleteFilterRequest);
  yield takeLatest(actions.removeCartProduct, removeCartProductRequest);
  yield takeLatest(actions.doUpdateCartQuentity, doUpdateCartQuentityRequest);
  
}
