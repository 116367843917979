import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../newTypes/RootState';

import { initialState } from './slice';

const selectDomain = (state: RootState) => {
  if (state) {  
    return state.disocuntState;
  } else {
    return initialState;
  }
};
export const selectList = createSelector([selectDomain], (state) => state.List);
export const SelectLoading = createSelector([selectDomain], (state) => state.loading);
export const SelectPageNo = createSelector([selectDomain], (state) => state.pageNo);
export const SelectPageSize = createSelector([selectDomain], (state) => state.pageSize);
export const SelectTotalRow = createSelector([selectDomain], (state) => state.totalRow);
export const SelectSearch = createSelector([selectDomain], (state) => state.search);
export const SelectFrom = createSelector([selectDomain], (state) => state.Form);
