import { call, delay, fork, put, select, take, takeLatest } from 'redux-saga/effects';
// import { selectLoginForm } from './selector';
import { actions } from './slice';
import { openSnackbar } from 'store/reducers/snackbar';
import { AxiosResponse } from 'axios';
import {
  SelectPageNo,
  SelectPageSize,
  SelectSearch,
  selectSettingForm,
  selectSelectedStroy,
  selectPromptForm,
  selectStoryForm,
  selectStoryTree,
  selectDynamicForm
} from './selector';
import { SettingState, StoryDynamicInterface, StoryInterface, StoryPromptInterface } from '../types';
import {
  DeleteCommunityStroyRequest,
  GetCodotStroylistRequest,
  GetCodotTextStroylistRequest,
  GetCommunityStoryEditRequest,
  GetCommunityStroylistRequest,
  GetDynamicEditRequest,
  GetPromptEditRequest,
  GetSettingFromRequest,
  GetStoryEditRequest,
  GetStroyDynamiclistRequest,
  GetStroylistRequest,
  GetStroyPromptlistRequest,
  GetStroyTreeFileUplodRequest,
  SaveDynamicStoryRequest,
  SavePromptsRequest,
  SaveSettingRequest,
  SaveStroyRequest,
  UpdateDynamicStoryRequest,
  UpdatePromptsRequest,
  UpdateStroyOnlyRequest,
  UpdateStroyRequest,
  UpdateStroyStatusRequest
} from 'utils copy/request';
import { END, eventChannel } from 'redux-saga';
import { RawNodeDatum } from 'react-d3-tree/lib/types/types/common';
import CatchBlockFunction from 'hooks/CatchBlockFunction';
export function* promptsAddRequest(action: { payload: { callback: any } }) {
  yield delay(500);
  const form: StoryPromptInterface = yield select(selectPromptForm);
  const story_id: string = yield select(selectSelectedStroy);
  if (form.title.length === 0) {
    openSnackbar({
      open: true,
      message: 'please fill all fields',
      variant: 'alert',
      alert: {
        color: 'error'
      },
      close: false
    });

    return;
  }

  console.log('saga working');
  let data = {
    title: form.title,
    story_id: story_id
  };

  try {
    const response: AxiosResponse = yield call(SavePromptsRequest, data);
    if (response.data.status == false) {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: false
        })
      );

      return;
    }
    yield put(
      openSnackbar({
        open: true,
        message: response.data.message,
        variant: 'alert',
        alert: {
          color: 'success'
        },
        close: false
      })
    );

    yield call(action?.payload?.callback);
  } catch (error: any) {
      CatchBlockFunction(error)
  }
}
export function* dynamicStoryAddRequest(action: { payload: { callback: any } }) {
  yield delay(500);
  const form: StoryDynamicInterface = yield select(selectDynamicForm);
  if (form.title.length === 0) {
    openSnackbar({
      open: true,
      message: 'please fill title',
      variant: 'alert',
      alert: {
        color: 'error'
      },
      close: false
    });

    return;
  }
  if (form.items.length === 0) {
    openSnackbar({
      open: true,
      message: 'please story items',
      variant: 'alert',
      alert: {
        color: 'error'
      },
      close: false
    });

    return;
  }

  console.log('saga working');

  try {
    const response: AxiosResponse = yield call(SaveDynamicStoryRequest, form);
    if (response.data.status == false) {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: false
        })
      );

      return;
    }
    yield put(
      openSnackbar({
        open: true,
        message: response.data.message,
        variant: 'alert',
        alert: {
          color: 'success'
        },
        close: false
      })
    );

    yield call(action?.payload?.callback);
  } catch (error: any) {
      CatchBlockFunction(error)
  }
}

export function* promptsUpdateRequest(action: { payload: { callback: any } }) {
  yield delay(500);
  const form: StoryPromptInterface = yield select(selectPromptForm);
  if (form.title.length === 0) {
    return;
  }
  try {
    const response: AxiosResponse = yield call(UpdatePromptsRequest, form);
    if (response.data.status == false) {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: false
        })
      );

      return;
    }
    yield put(
      openSnackbar({
        open: true,
        message: response.data.message,
        variant: 'alert',
        alert: {
          color: 'success'
        },
        close: false
      })
    );

    yield call(action?.payload?.callback);
  } catch (error: any) {
      CatchBlockFunction(error)
  }
}

export function* dynamicStoryUpdateRequest(action: { payload: { callback: any } }) {
  yield delay(500);
  const form: StoryDynamicInterface = yield select(selectDynamicForm);
  if (form.title.length === 0) {
    openSnackbar({
      open: true,
      message: 'please fill title',
      variant: 'alert',
      alert: {
        color: 'error'
      },
      close: false
    });

    return;
  }
  if (form.items.length === 0) {
    openSnackbar({
      open: true,
      message: 'please story items',
      variant: 'alert',
      alert: {
        color: 'error'
      },
      close: false
    });

    return;
  }
  try {
    const response: AxiosResponse = yield call(UpdateDynamicStoryRequest, form);
    if (response.data.status == false) {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: false
        })
      );

      return;
    }
    yield put(
      openSnackbar({
        open: true,
        message: response.data.message,
        variant: 'alert',
        alert: {
          color: 'success'
        },
        close: false
      })
    );

    yield call(action?.payload?.callback);
  } catch (error: any) {
      CatchBlockFunction(error)
  }
}
export function* saveSettingRequest(action: { payload: { callback: any } }) {
  yield delay(500);
  const form: SettingState = yield select(selectSettingForm);

  if (form.android == 0) {
    openSnackbar({
      open: true,
      message: 'please fill all fields',
      variant: 'alert',
      alert: {
        color: 'error'
      },
      close: false
    });

    return;
  }

  console.log('saga working');

  try {
    const response: AxiosResponse = yield call(SaveSettingRequest, form);
    if (response.data.status == false) {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: false
        })
      );

      return;
    }
    yield put(
      openSnackbar({
        open: true,
        message: response.data.message,
        variant: 'alert',
        alert: {
          color: 'success'
        },
        close: false
      })
    );

    yield call(action?.payload?.callback);
  } catch (error: any) {
      CatchBlockFunction(error)
  }
}
export function* deleteCommunityRequest(action: { payload: string }) {
  yield delay(500);
  if (action.payload.length === 0) {
    yield put(
      openSnackbar({
        open: true,
        message: 'Select Story to delete',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: false
      })
    );
    return;
  }

  try {
    const response: AxiosResponse = yield call(DeleteCommunityStroyRequest, action.payload);
    if (response.data.status == false) {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: false
        })
      );

      return;
    }
    yield put(
      openSnackbar({
        open: true,
        message: response.data.message,
        variant: 'alert',
        alert: {
          color: 'success'
        },
        close: false
      })
    );
    yield put(actions.doGetCommunityStoryList());
  } catch (error: any) {
      CatchBlockFunction(error)
  }
}
export function* getStoryList() {
  yield delay(500);

  try {
    const pageNo: number = yield select(SelectPageNo);
    const pageSize: number = yield select(SelectPageSize);
    const searchText: string = yield select(SelectSearch);
    const response: AxiosResponse = yield call(
      GetStroylistRequest,
      `?pageNo=${pageNo}&pageSize=${pageSize}${searchText.length > 0 ? '&search=' + searchText : ''}`
    );
    yield put(actions.setLoading(false));
    if (response.data.status == false) {
      // toast.error(response.data.message);
      return;
    }
    yield put(actions.setTotalRow(response.data.data.meta ? response.data.data.meta.total : 0));
    yield put(actions.setStroyList(response.data.data.results));
  } catch (error: any) {
   CatchBlockFunction(error)
  }
}
export function* getStoryPromptsList() {
  yield delay(500);

  try {
    const pageNo: number = yield select(SelectPageNo);
    const pageSize: number = yield select(SelectPageSize);
    const searchText: string = yield select(SelectSearch);
    const story_id: string = yield select(selectSelectedStroy);
    const response: AxiosResponse = yield call(
      GetStroyPromptlistRequest,
      `?pageNo=${pageNo}&pageSize=${pageSize}&story_id=${story_id}${searchText.length > 0 ? '&search=' + searchText : ''}`
    );
    yield put(actions.setLoading(false));
    if (response.data.status == false) {
      // toast.error(response.data.message);
      return;
    }
    yield put(actions.setTotalRow(response.data.data.meta ? response.data.data.meta.total : 0));
    yield put(actions.setStroyPromptList(response.data.data.results));
  } catch (error: any) {
   CatchBlockFunction(error)
  }
}

export function* getStoryDynamicList() {
  yield delay(500);

  try {
    const pageNo: number = yield select(SelectPageNo);
    const pageSize: number = yield select(SelectPageSize);
    const searchText: string = yield select(SelectSearch);

    const response: AxiosResponse = yield call(
      GetStroyDynamiclistRequest,
      `?pageNo=${pageNo}&pageSize=${pageSize}${searchText.length > 0 ? '&search=' + searchText : ''}`
    );
    yield put(actions.setLoading(false));
    if (response.data.status == false) {
      // toast.error(response.data.message);
      return;
    }
    yield put(actions.setTotalRow(response.data.data.meta ? response.data.data.meta.total : 0));
    yield put(actions.setStroyDynamicList(response.data.data.results));
  } catch (error: any) {
   CatchBlockFunction(error)
  }
}
function* watchOnProgress(chan: any) {
  while (true) {
    let data: number = yield take(chan);
    yield put(actions.updateStoryBranchFormValue({ key: 'progress', value: data }));
  }
}
export function* getSettingFrom() {
  yield delay(500);

  try {
    const response: AxiosResponse = yield call(GetSettingFromRequest);
    yield put(actions.setLoading(false));
    if (response.data.status == false) {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: false
        })
      );
      return;
    }
    yield put(actions.setSettingFromData(response.data.data));
  } catch (error: any) {
      CatchBlockFunction(error)
  }
}

export function* getCommuityStoryList() {
  yield delay(500);

  try {
    const pageNo: number = yield select(SelectPageNo);
    const pageSize: number = yield select(SelectPageSize);
    const searchText: string = yield select(SelectSearch);
    const response: AxiosResponse = yield call(
      GetCommunityStroylistRequest,
      `?pageNo=${pageNo}&pageSize=${pageSize}${searchText.length > 0 ? '&search=' + searchText : ''}`
    );
    yield put(actions.setLoading(false));
    if (response.data.status == false) {
      // toast.error(response.data.message);
      return;
    }
    yield put(actions.setTotalRow(response.data.data.meta ? response.data.data.meta.total : 0));
    yield put(actions.setCommunityStroyList(response.data.data.results));
  } catch (error: any) {
      CatchBlockFunction(error)
  }
}
export function* getStoryEditRequest(action: { payload: string }) {
  yield delay(500);

  try {
    const response: AxiosResponse = yield call(GetStoryEditRequest, action.payload);
    if (response.data.status == false) {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: false
        })
      );

      return;
    }

    yield put(actions.setStroyFromList(response.data.data));
  } catch (error: any) {
      CatchBlockFunction(error)
  }
}
export function* getPromptEditRequest(action: { payload: string }) {
  yield delay(500);

  try {
    const response: AxiosResponse = yield call(GetPromptEditRequest, action.payload);
    if (response.data.status == false) {
      // toast.error(response.data.message);
      return;
    }

    yield put(actions.setStroyPromptsFrom(response.data.data));
  } catch (error: any) {
      CatchBlockFunction(error)
  }
}
export function* getDynamicEditRequest(action: { payload: string }) {
  yield delay(500);

  try {
    const response: AxiosResponse = yield call(GetDynamicEditRequest, action.payload);
    if (response.data.status == false) {
      // toast.error(response.data.message);
      return;
    }

    yield put(actions.setStroyDynamicFrom(response.data.data));
  } catch (error: any) {
      CatchBlockFunction(error)
  }
}
export function* getEditCommuniytStoryRequest(action: { payload: string }) {
  yield delay(500);
  try {
    const response: AxiosResponse = yield call(GetCommunityStoryEditRequest, action.payload);
    if (response.data.status == false) {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: false
        })
      );
      return;
    }
    yield put(actions.setCommunityEditStory(response.data.data));
  } catch (error: any) {
      CatchBlockFunction(error)
  }
}

export function* stroyCreateRequest(action: { payload: { callback: any } }) {
  yield delay(500);

  try {
    let form: StoryInterface = yield select(selectStoryForm);
    //@ts-ignore
    let fromtree = yield select(selectStoryTree);
    //form.tree = fromtree;
    let fromData = { ...form };
    fromData.tree = fromtree;
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(SaveStroyRequest, fromData);
    yield put(actions.setLoading(false));
    if (response.data.status == false) {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: false
        })
      );
      return;
    }
    yield put(actions.clearNewStoryFrom());
    yield put(
      openSnackbar({
        open: true,
        message: response.data.message,
        variant: 'alert',
        alert: {
          color: 'success'
        },
        close: false
      })
    );

    yield call(action?.payload?.callback);
  } catch (error: any) {
    yield put(actions.setLoading(false));
      CatchBlockFunction(error)
  }
}

export function* stroyUpdateRequest(action: { payload: { callback: any } }) {
  yield delay(500);

  try {
    let form: StoryInterface = yield select(selectStoryForm);
    let fromtree: RawNodeDatum = yield select(selectStoryTree);
    let fromData = { ...form };
    fromData.tree = fromtree;
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(UpdateStroyRequest, fromData);
    yield put(actions.setLoading(false));
    if (response.data.status == false) {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: false
        })
      );
      return;
    }
    yield put(actions.clearNewStoryFrom());
    yield put(
      openSnackbar({
        open: true,
        message: response.data.message,
        variant: 'alert',
        alert: {
          color: 'success'
        },
        close: false
      })
    );

    yield call(action?.payload?.callback);
  } catch (error: any) {
    yield put(actions.setLoading(false));
      CatchBlockFunction(error)
  }
}
export function* onlyStroyUpdateRequest(action: { payload: { callback: any } }) {
  yield delay(500);

  try {
    let form: StoryInterface = yield select(selectStoryForm);
   
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(UpdateStroyOnlyRequest, form);
    yield put(actions.setLoading(false));
    if (response.data.status == false) {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: false
        })
      );
      return;
    }
    yield put(actions.clearNewStoryFrom());
    yield put(
      openSnackbar({
        open: true,
        message: response.data.message,
        variant: 'alert',
        alert: {
          color: 'success'
        },
        close: false
      })
    );

    yield call(action?.payload?.callback);
  } catch (error: any) {
    yield put(actions.setLoading(false));
      CatchBlockFunction(error)
  }
}
export function* stroyUpdateStatusRequest(action: { payload: { id: string; type: string } }) {
  yield delay(500);

  try {
    
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(UpdateStroyStatusRequest, action.payload);
    yield put(actions.setLoading(false));
    if (response.data.status == false) {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: false
        })
      );
      return;
    }   
    yield put(
      openSnackbar({
        open: true,
        message: response.data.message,
        variant: 'alert',
        alert: {
          color: 'success'
        },
        close: false
      })
    );
    yield put(
      actions.doGetStoryList()
    );
  } catch (error: any) {
    yield put(actions.setLoading(false));
      CatchBlockFunction(error)
  }
}
export function* getCodotStoryList() {
  yield delay(500);

  try {
    const response: AxiosResponse = yield call(GetCodotStroylistRequest);
    yield put(actions.setLoading(false));
    if (response.data.status == false) {
      // toast.error(response.data.message);
      return;
    }
    yield put(actions.setStroyCodotList(response.data.data));
  } catch (error: any) {
   CatchBlockFunction(error)
  }
}

export function* getCodotTextStoryList() {
  yield delay(500);

  try {
    const response: AxiosResponse = yield call(GetCodotTextStroylistRequest);
    yield put(actions.setLoading(false));
    if (response.data.status == false) {
      // toast.error(response.data.message);
      return;
    }
    yield put(actions.setStroyTextList(response.data.data));
  } catch (error: any) {
   CatchBlockFunction(error)
  }
}

export function* getEditStoryRequest(action: { payload: string }) {
  yield delay(500);
  try {
    const response: AxiosResponse = yield call(GetStoryEditRequest, action.payload);
    if (response.data.status == false) {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: false
        })
      );
      return;
    }

    yield put(actions.setEditStory(response.data));
  } catch (error: any) {
      CatchBlockFunction(error)
  }
}
function createUploader(payload: any) {
  let emit: any;
  const chan = eventChannel((emitter) => {
    emit = emitter;
    return () => {};
  });

  const uploadPromise = GetStroyTreeFileUplodRequest(payload, (progressEvent: any) => {
    let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
    if (percentCompleted == 100) emit(END);
    else emit(percentCompleted);
  });

  return [uploadPromise, chan];
}

export function* stroyTreeFileUploadRequest(action: { payload: { data: any; type: number; callback: any } }) {
  yield delay(500);

  try {
    if (action.payload.type == 1) {
      yield put(actions.updateStoryBranchFormValue({ key: 'isUpload', value: true }));
    } else {
      yield put(actions.updateStoryBranchFormValue({ key: 'isSrtUpload', value: true }));
    }

    const [uploadPromise, chan] = createUploader(action.payload.data);
    yield fork(watchOnProgress, chan);
    const response: AxiosResponse = yield call(() => uploadPromise);
    if (response.data.status == false) {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: false
        })
      );
      return;
    }
    yield put(
      openSnackbar({
        open: true,
        message: response.data.message,
        variant: 'alert',
        alert: {
          color: 'success'
        },
        close: false
      })
    );

    if (action.payload.type == 1) {
      yield put(actions.updateStoryBranchFormValue({ key: 'filename', value: response.data.name }));
      yield put(actions.updateStoryBranchFormValue({ key: 'filemainname', value: response.data.mainfile }));
      yield put(actions.updateStoryBranchFormValue({ key: 'isUpload', value: false }));
    } else {
      yield put(actions.updateStoryBranchFormValue({ key: 'srtfilename', value: response.data.name }));
      yield put(actions.updateStoryBranchFormValue({ key: 'srtfilemainname', value: response.data.mainfile }));
      yield put(actions.updateStoryBranchFormValue({ key: 'isSrtUpload', value: false }));
    }

    yield call(action?.payload?.callback);
  } catch (error: any) {
      CatchBlockFunction(error)
  }
}

export function* catelogRepoSaga() {
  yield takeLatest(actions.doGetStoryList, getStoryList);
  yield takeLatest(actions.doGetCommunityStoryList, getCommuityStoryList);
  yield takeLatest(actions.doDeleteCommunityStory, deleteCommunityRequest);
  yield takeLatest(actions.doSaveSetting, saveSettingRequest);
  yield takeLatest(actions.doGetSetting, getSettingFrom);
  // prompts
  yield takeLatest(actions.doUpdateStoryPrompt, promptsUpdateRequest);
  yield takeLatest(actions.NewStoryPromptAdded, promptsAddRequest);
  yield takeLatest(actions.getPromptDataForEdit, getPromptEditRequest);
  yield takeLatest(actions.doGetStoryPropmtsList, getStoryPromptsList);
  // dynamic Story
  yield takeLatest(actions.doUpdateStoryDynamic, dynamicStoryUpdateRequest);
  yield takeLatest(actions.NewStoryDynamicAdded, dynamicStoryAddRequest);
  yield takeLatest(actions.getDynamicDataForEdit, getDynamicEditRequest);
  yield takeLatest(actions.doGetStoryDynamicList, getStoryDynamicList);
  yield takeLatest(actions.doGetStoryCodotList, getCodotStoryList);
  yield takeLatest(actions.doGetStoryTextList, getCodotTextStoryList);

  yield takeLatest(actions.getCommunityDataForEdit, getEditCommuniytStoryRequest);
  yield takeLatest(actions.getDataForEdit, getEditStoryRequest);
  yield takeLatest(actions.doStoryTreeFileUpload, stroyTreeFileUploadRequest);
  yield takeLatest(actions.NewStoryAdded, stroyCreateRequest);
  yield takeLatest(actions.doUpdateStory, stroyUpdateRequest);
  yield takeLatest(actions.doUpdateStoryOnly, onlyStroyUpdateRequest);
  
  yield takeLatest(actions.doUpdateStoryStatus, stroyUpdateStatusRequest);

  // yield all([watchRequests]);
}
