import { call, delay, put, select, takeLatest } from 'redux-saga/effects';
import { actions } from './slice';
import { openSnackbar } from 'store/reducers/snackbar';
import { AxiosResponse } from 'axios';
import {
  SelectDetailsPageNo,
  SelectDetailsPageSize,
  SelectPageNo,
  SelectPageSize,
  SelectSearch,
  SelectStudentAddForm,
  SelectStudenttype,
  selectStudentAddCoinsForm
} from './selector';

import {
  doGetStudentList,
  doUpdateStudent,
  doAddStudent,
  doGetStudentEdit,
  doDeleteStudent,
  doAddCoinsStudent,
  doGetStudentDetailsList
} from 'utils copy/request';
import { StudentAddCoinInterface, StudentAddInterface, StudentType } from '../types';
import CatchBlockFunction from 'hooks/CatchBlockFunction';
import { FilterInterface } from 'pages/Report/types';
import { selectReportPageFilter } from 'pages/Report/redux/selector';
export function* doStudentAddRequest(action: { payload: { callback: any } }) {
  yield delay(500);
  const form: StudentAddInterface = yield select(SelectStudentAddForm);
  if (form.firstName.length === 0) {
    yield put(
      openSnackbar({
        open: true,
        message: 'please fill first name',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      })
    );

    return;
  }
  if (form.lastName.length === 0) {
    yield put(
      openSnackbar({
        open: true,
        message: 'please fill last name',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      })
    );

    return;
  }
  if (form.email.length === 0) {
    yield put(
      openSnackbar({
        open: true,
        message: 'please fill email',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      })
    );

    return;
  }
  if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(form.email)) {
    yield put(
      openSnackbar({
        open: true,
        message: 'please enter valid email',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      })
    );
    return;
  }
  if (form.section.length === 0) {
    yield put(
      openSnackbar({
        open: true,
        message: 'please fill Section',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      })
    );

    return;
  }
  if (form.class.length === 0) {
    yield put(
      openSnackbar({
        open: true,
        message: 'please fill Class',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      })
    );

    return;
  }
  if (form.password.length === 0) {
    yield put(
      openSnackbar({
        open: true,
        message: 'please fill password',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      })
    );

    return;
  }

  if (form.mobileNumber.length === 0) {
    yield put(
      openSnackbar({
        open: true,
        message: 'please fill mobileNumber',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      })
    );

    return;
  }
  if (form.mobileNumber.toString().length != 10) {
    yield put(
      openSnackbar({
        open: true,
        message: 'please Enter Valid mobileNumber',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      })
    );

    return;
  }
  if (form.zip != 0) {
    if (form.zip.toString().length != 6) {
      yield put(
        openSnackbar({
          open: true,
          message: 'Enter Valid ZIP Code',
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true
        })
      );

      return;
    }
  }

  try {
    const response: AxiosResponse = yield call(doAddStudent, form);
    if (response.data.status == false) {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true
        })
      );

      return;
    }
    yield put(
      openSnackbar({
        open: true,
        message: response.data.message,
        variant: 'alert',
        alert: {
          color: 'success'
        },
        close: true
      })
    );

    yield call(action?.payload?.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* doGetAllStudentListRequest() {
  yield delay(500);

  try {
    const pageNo: number = yield select(SelectPageNo);
    const pageSize: number = yield select(SelectPageSize);
    const filter: FilterInterface = yield select(selectReportPageFilter);
    const searchText: string = yield select(SelectSearch);
    const studentType: StudentType = yield select(SelectStudenttype);
    const response: AxiosResponse = yield call(
      doGetStudentList,
      `?pageNo=${pageNo}&pageSize=${pageSize}${searchText.length > 0 ? '&search=' + searchText : ''}&teacherFilter=${
        filter.selectedTechaerFilter
      }&classFilter=${filter.selectedClassFilter}&studentType=${studentType}`
    );
    yield put(actions.setLoading(false));
    if (response.data.status == false) {
      return;
    }
    console.log(response, 'response');

    yield put(actions.setTotalRow(response.data[0].meta ? response.data[0].meta.total : 0));
    yield put(actions.setList(response.data[0].results));
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}

export function* doGetEditRequest(action: { payload: string }) {
  yield delay(500);
  try {
    const response: AxiosResponse = yield call(doGetStudentEdit, action.payload);
    if (response.data.status == false) {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true
        })
      );
      return;
    }
    yield put(actions.setDataInForm(response.data.data[0]));
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* doStudentUpdateRequest(action: { payload: { callback: any } }) {
  yield delay(500);
  debugger;
  const form: StudentAddInterface = yield select(SelectStudentAddForm);
  if (form.firstName.length === 0) {
    yield put(
      openSnackbar({
        open: true,
        message: 'please fill first name',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      })
    );

    return;
  }
  if (form.lastName.length === 0) {
    yield put(
      openSnackbar({
        open: true,
        message: 'please fill last name',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      })
    );

    return;
  }
  if (form.email.length === 0) {
    yield put(
      openSnackbar({
        open: true,
        message: 'please fill email',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      })
    );

    return;
  }
  if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(form.email)) {
    yield put(
      openSnackbar({
        open: true,
        message: 'please enter valid email',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      })
    );
    return;
  }
  if (form.section.length === 0) {
    yield put(
      openSnackbar({
        open: true,
        message: 'please fill Section',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      })
    );

    return;
  }
  if (form.class.length === 0) {
    yield put(
      openSnackbar({
        open: true,
        message: 'please fill Class',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      })
    );

    return;
  }

  if (form.mobileNumber.length === 0) {
    yield put(
      openSnackbar({
        open: true,
        message: 'please fill mobileNumber',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      })
    );

    return;
  }
  if (form.mobileNumber.toString().length != 10) {
    yield put(
      openSnackbar({
        open: true,
        message: 'please Enter Valid mobileNumber',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      })
    );

    return;
  }
  if (form.zip != 0) {
    if (form.zip.toString().length != 6) {
      yield put(
        openSnackbar({
          open: true,
          message: 'Enter Valid ZIP Code',
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true
        })
      );

      return;
    }
  }

  try {
    const response: AxiosResponse = yield call(doUpdateStudent, form);
    if (response.data.status == false) {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true
        })
      );

      return;
    }
    yield put(
      openSnackbar({
        open: true,
        message: response.data.message,
        variant: 'alert',
        alert: {
          color: 'success'
        },
        close: true
      })
    );

    yield call(action?.payload?.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* deleteFilterRequest(action: { payload: { id: string; callback: any } }) {
  yield delay(500);
  if (action.payload.id.length === 0) {
    yield put(
      openSnackbar({
        open: true,
        message: 'Select Story to delete',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      })
    );
    return;
  }

  try {
    const response: AxiosResponse = yield call(doDeleteStudent, action.payload.id);
    if (response.data.status == false) {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true
        })
      );

      return;
    }
    yield put(
      openSnackbar({
        open: true,
        message: response.data.message,
        variant: 'alert',
        alert: {
          color: 'success'
        },
        close: true
      })
    );
    yield call(action?.payload?.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}

//add coins
export function* doAddCoinsRequest(action: { payload: { id: string; callback: any } }) {
  yield delay(500);
  const form: StudentAddCoinInterface = yield select(selectStudentAddCoinsForm);

  if (form.coins === 0) {
    yield put(
      openSnackbar({
        open: true,
        message: 'please fill Coins',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      })
    );
    return;
  }
  if (form.coins > 0) {
    if (form.comment.length === 0) {
      yield put(
        openSnackbar({
          open: true,
          message: 'please Enter Comment',
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true
        })
      );
      return;
    }
  }

  try {
    const response: AxiosResponse = yield call(doAddCoinsStudent, form, action.payload.id);
    if (response.data.status == false) {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true
        })
      );

      return;
    }
    yield put(
      openSnackbar({
        open: true,
        message: response.data.message,
        variant: 'alert',
        alert: {
          color: 'success'
        },
        close: true
      })
    );

    yield call(action?.payload?.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* doGetStudentDetailsListRequest(action: { payload: { id: string; callback: any } }) {
  yield delay(500);
  try {
    const pageNo: number = yield select(SelectDetailsPageNo);
    const pageSize: number = yield select(SelectDetailsPageSize);
    const response: AxiosResponse = yield call(doGetStudentDetailsList, action.payload.id, `?pageNo=${pageNo}&pageSize=${pageSize}`);
    yield put(actions.setLoading(false));
    if (response.data.status == false) {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'success'
          },
          close: true
        })
      );
      return;
    }

    yield put(actions.setDetailsTotalRow(response.data.data[0].meta ? response.data.data[0].meta.total : 0));
    yield put(actions.setDetailsList(response.data.data[0].results));
    yield call(action?.payload?.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* filterRepoSaga() {
  yield takeLatest(actions.doAddStudent, doStudentAddRequest);
  yield takeLatest(actions.doGetStudent, doGetAllStudentListRequest);
  yield takeLatest(actions.doStudentEdit, doGetEditRequest);
  yield takeLatest(actions.doStudentUpdate, doStudentUpdateRequest);
  yield takeLatest(actions.doStudentDelete, deleteFilterRequest);
  yield takeLatest(actions.doAddCoinBYId, doAddCoinsRequest);
  yield takeLatest(actions.doGetStudentDetails, doGetStudentDetailsListRequest);
}
