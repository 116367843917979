import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '../../../utils copy/@reduxjs/toolkit';
import {
  CommunityInterface,
  SettingState,
  StoryDynamicInterface,
  StoryFilterItem,
  StoryInterface,
  StoryPromptInterface,
  StoryState
} from '../types';
import set from 'lodash/set';

export const initialState: StoryState = {
  loading: false,
  pageSize: 10,
  totalRow: 0,
  search: '',
  pageNo: 1,
  StoryList: [],
  StoryFormData: {
    orderStory: [],
    order: '',
    id: '',
    image_path: '',
    main: '',
    creative_director: '',
    description: '',
    tags: '',
    status: 0,
    poster_path: '',
    poster: '',
    sound_engineer: '',
    thumb_path: '',
    thumb: '',
    title: '',
    voice: '',
    StoryParts: [],
    StoryPartsDecision: [],
    tree: {},
    is_paid: 0,
    is_show_banner: 0,
    is_show_new: 0,
    loading: false,
    filters: [],
    itemFilter: ''
  },

  StoryBranchFormData: {
    BranchAudio: '',
    BranchDecisionOneID: '',
    BranchDecisionOneTitle: '',
    BranchDecisionTwoID: '',
    BranchisActive: true,
    BranchSUbTitle: '',
    Branchtitle: '',
    Branchid: ''
  },

  CommunityStoryFormData: {
    id: '',
    bannerImage: '',
    creativeDirector: '',
    description: '',
    filterSelectOption: [],
    isActive: true,
    mainImage: '',
    soundEngineer: '',
    thumbImage: '',
    title: '',
    voice: ''
  },
  StorPromptyList: [],
  StoryPromptFormData: {
    id: '',
    title: '',
    story_id: ''
  },
  CommunityStoryList: [],
  editIndex: 0,
  isadd: false,
  settingForm: { android: 0, suscribe_discount: 0 },
  selectedStory: '',
  communityStoryEdit: { id: '', title: '', about: '', color: '', why: '', status: 0, StoryParts: [], StoryPartsDecision: [] },
  storyEditDataModel: false,
  storyEditPartModel: false,
  storyEndModal: false,
  storyAddPartModel: false,
  storyEditForm: {
    nodeid: 0,
    dedesign1_id: '',
    dedesign2_id: '',
    filemainname: '',
    filename: '',
    nodedesign1: '',
    nodedesign1amount: 0,
    nodedesign1id: 0,
    nodedesign1isprimeum: 0,
    nodedesign2: '',
    nodedesign2amount: 0,
    nodedesign2id: 0,
    nodedesign2isprimeum: 0,
    nodetitle: '',
    srtfilemainname: '',
    srtfilename: '',
    story_id: '',
    isUpload: false,
    progress: 0,
    isSrtUpload: false,
    srtProgress: 0,
    child_dedesign1_id: '',
    child_dedesign2_id: ''
  },
  treedata: null,
  StoryDynamicList: [],
  StoryDynamicForm: { id: '', items: [], title: '', type: 1, status: 0, order: 0 },
  ActiveCommunityStory: [],
  ActiveStory: []
};

export const useClinicSlice = createSlice({
  name: 'storyState',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },

    setPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },
    setPageNo: (state, action: PayloadAction<number>) => {
      state.pageNo = action.payload;
    },
    setTotalRow: (state, action: PayloadAction<number>) => {
      state.totalRow = action.payload;
    },
    setSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
    },
    setSelectedStroy: (state, action: PayloadAction<string>) => {
      state.selectedStory = action.payload;
    },
    setEditDataModel: (state) => {
      state.storyEditDataModel = !state.storyEditDataModel;
    },
    setEditPartModel: (state) => {
      state.storyEditPartModel = !state.storyEditPartModel;
    },
    setEndModel: (state) => {
      state.storyEndModal = !state.storyEndModal;
    },
    setAddPartModal: (state) => {
      state.storyAddPartModel = !state.storyAddPartModel;
    },

    setStoryTree: (state, action: PayloadAction<any>) => {
      state.treedata = action.payload;
    },
    setStoryParts: (state, action: PayloadAction<Array<any>>) => {
      set(state, `StoryFormData.StoryParts`, action.payload);
    },
    setStoryPartsDicision: (state, action: PayloadAction<Array<any>>) => {
      set(state, `StoryFormData.StoryPartsDecision`, action.payload);
    },
    updateNewStoryFormValue: (state, action: PayloadAction<{ key: string; value: any }>) => {
      console.log('action.payload.key,action.payload.value', action.payload.key, action.payload.value);
      set(state, `StoryFormData.${action.payload.key}`, action.payload.value);
    },
    NewStoryAdded: (state, action: PayloadAction<{ callback: () => void }>) => {
      console.log('Yo Reached at UserAdded');
    },

    doUpdateStory: (state, action: PayloadAction<{ callback: () => void }>) => {
      console.log('Yo Reached at UserAdded');
    },
    doUpdateStoryOnly: (state, action: PayloadAction<{ callback: () => void }>) => {
      console.log('Yo Reached at UserAdded');
    },
    doUpdateStoryStatus: (state, action: PayloadAction<{ id: string; type: string }>) => {
      console.log('Yo Reached at UserAdded');
    },
    doStoryTreeFileUpload: (state, action: PayloadAction<{ data: any; type: number; callback: any }>) => {
      console.log('addimages');
      // state.phone = action.payload;
    },
    clearNewStoryFrom: (state) => {
      state.StoryFormData.StoryParts = [];
      state.StoryFormData.StoryPartsDecision = [];
      state.StoryFormData.creative_director = '';
      state.StoryFormData.description = '';
      state.StoryFormData.id = '';
      state.StoryFormData.main = '';
      state.StoryFormData.image_path = '';
      state.StoryFormData.poster = '';
      state.StoryFormData.poster_path = '';
      state.StoryFormData.sound_engineer = '';
      state.StoryFormData.status = 0;
      state.StoryFormData.status = 0;
    },
    clearEditStoryPartFrom: (state) => {
      state.storyEditForm.nodeid = 0;
      state.storyEditForm.dedesign1_id = '';
      state.storyEditForm.dedesign2_id = '';
      state.storyEditForm.filemainname = '';
      state.storyEditForm.filename = '';
      state.storyEditForm.nodedesign1 = '';
      state.storyEditForm.nodedesign1amount = 0;
      state.storyEditForm.nodedesign1id = 0;
      state.storyEditForm.nodedesign1isprimeum = 0;
      state.storyEditForm.nodedesign2 = '';
      state.storyEditForm.nodedesign2amount = 0;
      state.storyEditForm.nodedesign2id = 0;
      state.storyEditForm.nodedesign2isprimeum = 0;
      state.storyEditForm.nodetitle = '';
      state.storyEditForm.srtfilemainname = '';
      state.storyEditForm.srtfilename = '';
      state.storyEditForm.story_id = '';
      state.storyEditForm.isUpload = false;
      state.storyEditForm.progress = 0;
      state.storyEditForm.isSrtUpload = false;
      state.storyEditForm.srtProgress = 0;
      state.storyEditForm.child_dedesign1_id = '';
      state.storyEditForm.child_dedesign2_id = '';
    },
    clearNewDynamicStoryFrom: (state) => {
      state.StoryDynamicForm.items = [];
      state.StoryDynamicForm.id = '';
      state.StoryDynamicForm.title = '';
      state.StoryDynamicForm.type = 1;
      state.StoryDynamicForm.status = 0;
      state.StoryDynamicForm.order = 0;
    },
    updateStoryBranchFormValue: (state, action: PayloadAction<{ key: string; value: any }>) => {
      console.log('action.payload.key,action.payload.value', action.payload.key, action.payload.value);
      set(state, `storyEditForm.${action.payload.key}`, action.payload.value);
    },

    getDataForEdit: (state, action: PayloadAction<string>) => {},
    setEditStory: (state, action: PayloadAction<any>) => {
      state.StoryFormData = action.payload.data;
      state.StoryFormData.tags = action.payload.titletags;
      state.StoryFormData.StoryParts = action.payload.StoryParts;
      state.StoryFormData.StoryPartsDecision = action.payload.StoryPartsDecision;
    },
    doGetStoryList: (state) => {},

    setStroyList: (state, action: PayloadAction<Array<StoryInterface>>) => {
      state.StoryList = action.payload;
    },
    setStroyFromList: (state, action: PayloadAction<StoryInterface>) => {
      state.StoryFormData = action.payload;
    },

    //Prompts
    NewStoryPromptAdded: (state, action: PayloadAction<{ callback: () => void }>) => {
      console.log('Yo Reached at UserAdded');
    },
    doGetStoryPropmtsList: (state) => {},
    setStroyPromptList: (state, action: PayloadAction<Array<StoryPromptInterface>>) => {
      state.StorPromptyList = action.payload;
    },
    getPromptDataForEdit: (state, action: PayloadAction<string>) => {},

    setStroyPromptsFrom: (state, action: PayloadAction<StoryPromptInterface>) => {
      state.StoryPromptFormData = action.payload;
    },
    doUpdateStoryPrompt: (state, action: PayloadAction<{ callback: () => void }>) => {
      console.log('Yo Reached at UserAdded');
    },
    //Community

    updateNewCommunityStoryFormValue: (state, action: PayloadAction<{ key: string; value: any }>) => {
      console.log('action.payload.key,action.payload.value', action.payload.key, action.payload.value);
      set(state, `CommunityStoryFormData.${action.payload.key}`, action.payload.value);
    },
    NewCommunityStoryAdded: (state, action: PayloadAction<{ callback: () => void }>) => {
      console.log('Yo Reached at UserAdded');
    },
    clearNewCommunityStoryFrom: (state) => {},
    clearPromptForm: (state) => {
      state.StoryPromptFormData.id = '';
      state.StoryPromptFormData.title = '';
    },
    updateCommunityStoryBranchFormValue: (state, action: PayloadAction<{ key: string; value: any }>) => {
      console.log('action.payload.key,action.payload.value', action.payload.key, action.payload.value);
      set(state, `CommunityStoryBranchFormData.${action.payload.key}`, action.payload.value);
    },
    updateStoryPromptFormValue: (state, action: PayloadAction<{ key: string; value: any }>) => {
      console.log('action.payload.key,action.payload.value', action.payload.key, action.payload.value);
      set(state, `StoryPromptFormData.${action.payload.key}`, action.payload.value);
    },

    //Dynamic
    NewStoryDynamicAdded: (state, action: PayloadAction<{ callback: () => void }>) => {
      console.log('Yo Reached at UserAdded');
    },
    doGetStoryDynamicList: (state) => {},
    doGetStoryCodotList: (state) => {},
    doGetStoryTextList: (state) => {},

    setStroyDynamicList: (state, action: PayloadAction<Array<StoryDynamicInterface>>) => {
      state.StoryDynamicList = action.payload;
    },
    setStroyCodotList: (state, action: PayloadAction<Array<any>>) => {
      state.ActiveStory = action.payload;
    },
    setStroyTextList: (state, action: PayloadAction<Array<any>>) => {
      state.ActiveCommunityStory = action.payload;
    },
    getDynamicDataForEdit: (state, action: PayloadAction<string>) => {},

    setStroyDynamicFrom: (state, action: PayloadAction<StoryDynamicInterface>) => {
      state.StoryDynamicForm = action.payload;
    },
    doUpdateStoryDynamic: (state, action: PayloadAction<{ callback: () => void }>) => {
      console.log('Yo Reached at UserAdded');
    },
    updateStoryDynamicFormValue: (state, action: PayloadAction<{ key: string; value: any }>) => {
      console.log('action.payload.key,action.payload.value', action.payload.key, action.payload.value);
      set(state, `StoryDynamicForm.${action.payload.key}`, action.payload.value);
    },

    updateSettingFormValue: (state, action: PayloadAction<{ key: string; value: any }>) => {
      console.log('action.payload.key,action.payload.value', action.payload.key, action.payload.value);
      set(state, `settingForm.${action.payload.key}`, action.payload.value);
    },
    NewCommunityStoryBranchAdded: (state, action: PayloadAction<{ callback: () => void }>) => {
      console.log('Yo Reached at UserAdded');
    },

    getCommunityDataForEdit: (state, action: PayloadAction<string>) => {},
    setCommunityEditStory: (state, action: PayloadAction<any>) => {
      state.communityStoryEdit = action.payload;
    },
    doGetCommunityStoryList: (state) => {},
    doGetSetting: (state) => {},
    setSettingFromData: (state, action: PayloadAction<SettingState>) => {
      state.settingForm = action.payload;
    },
    doSaveSetting: (state, action: PayloadAction<{ callback: () => void }>) => {},
    doDeleteCommunityStory: (state, action: PayloadAction<string>) => {},
    setCommunityStroyList: (state, action: PayloadAction<Array<CommunityInterface>>) => {
      state.CommunityStoryList = action.payload;
    },
    doGetChildCategoryList: (state) => {},
    addItemList: (state, action: PayloadAction<StoryFilterItem>) => {
      if (!state.StoryFormData.filters) {
        state.StoryFormData.filters = [];
      }

      state.StoryFormData.filters?.push(action.payload);
      state.StoryFormData.itemFilter = '';
    },
    deleteItemList: (state, action: PayloadAction<number>) => {
      state.StoryFormData.filters?.splice(action.payload, 1);
    },
    updateItemList: (state, action: PayloadAction<{ index: number; data: StoryFilterItem }>) => {
      if (state.StoryFormData.filters) {
        state.StoryFormData.filters[action.payload.index] = action.payload.data;
      }
    }
  }
});

export const { reducer, actions, name: sliceKey } = useClinicSlice;
