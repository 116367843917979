import dashboard from './dashboard';
import { NavItemType } from 'types/menu';
import custom from './custom';
import { RolesEnum } from 'sections/auth/types';
import customteacher from './customteacher';
import customuser from './customuser';

// ==============================|| MENU ITEMS ||============================== //

const menuItemss = (Role?: RolesEnum): { items: NavItemType[] } => {
  console.log('====================================');
  console.log('Role', Role);
  console.log('====================================');
  const menuItems: { items: NavItemType[] } = {
    items:
      Role == RolesEnum.ADMIN
        ? [dashboard, custom]
        : Role == RolesEnum.TEACHER
        ? [dashboard, customteacher]
        : Role == RolesEnum.STUDENT
        ? [dashboard, customuser]
        : []
  };

  return menuItems;
};

export default menuItemss;
// import React from 'react';
// import { RolesEnum } from 'sections/auth/types';
// import customteacher from './customteacher';
// import customuser from './customuser';

// function menuItems = (items: NavItemType[])=> {
//   const userData = useSelector(selectUserData);

// }

// export default menuItems;
