import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '../../../utils copy/@reduxjs/toolkit';
import { SubscriptionInterface, SubscriptionState } from '../types';
import set from 'lodash/set';
export const initialState: SubscriptionState = {
  loading: false,
  pageSize: 10,
  totalRow: 0,
  search: '',
  pageNo: 1,
  editIndex: 0,
  isadd: false,
  Form: {
    id: '',
    amount: 0,
    description: '',
    items: [],
    status: 0,
    title: '',
    type: 1,
    itemTitle:""
  },
  List: []
};

export const useClinicSlice = createSlice({
  name: 'subscriptionState',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },

    setPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },
    setPageNo: (state, action: PayloadAction<number>) => {
      state.pageNo = action.payload;
    },
    setTotalRow: (state, action: PayloadAction<number>) => {
      state.totalRow = action.payload;
    },
    setSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
    },
    updateFormValue: (state, action: PayloadAction<{ key: string; value: any }>) => {
      console.log('action.payload.key,action.payload.value', action.payload.key, action.payload.value);
      set(state, `Form.${action.payload.key}`, action.payload.value);
    },
    doGetList: (state) => {},

    doAdd: (state, action: PayloadAction<{ callback: () => void }>) => {
      console.log('Yo Reached at UserAdded');
    },
    doUpdate: (state, action: PayloadAction<{ callback: () => void }>) => {
      console.log('Yo Reached at UserAdded');
    },

    setUserGroupEdit: (state, action: PayloadAction<SubscriptionInterface>) => {
      state.Form = action.payload;
    },
    setList: (state, action: PayloadAction<Array<SubscriptionInterface>>) => {
      state.List = action.payload;
    },
    addItemList: (state, action: PayloadAction<string>) => {
      
      state.Form.items.push(action.payload);
      state.Form.itemTitle=""
    },
    deleteItemList: (state, action: PayloadAction<number>) => {
      state.Form.items.splice(action.payload,1);
    },

    getDataForEdit: (state, action: PayloadAction<string>) => {},
    clearFrom: (state) => {
      state.Form.status = 0;
      state.Form.title = '';
      state.Form.description = '';
      state.Form.items = [];
      state.Form.amount = 0;
      state.Form.type = 1;
    }
  }
});

export const { reducer, actions, name: sliceKey } = useClinicSlice;
