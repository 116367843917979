import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '../../../utils copy/@reduxjs/toolkit';
import { UserGroupInterface, UserGroupState } from '../types';
import set from 'lodash/set';
import { UserInterface } from 'pages/UserMain/types';
export const initialState: UserGroupState = {
  loading: false,
  pageSize: 10,
  totalRow: 0,
  search: '',
  pageNo: 1,
  editIndex: 0,
  isadd: false,
  selectedUser: [],
  UserGroupForm: {
    id: '',
    items: [],
    status: 0,
    title: ''
  },
  UserGroupList: [],
  UserList: [],
  activeUserGroupList:[]
};

export const useClinicSlice = createSlice({
  name: 'UserGroupState',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },

    setPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },
    setPageNo: (state, action: PayloadAction<number>) => {
      state.pageNo = action.payload;
    },
    setTotalRow: (state, action: PayloadAction<number>) => {
      state.totalRow = action.payload;
    },
    setSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
    },
    updateGroupUserFormValue: (state, action: PayloadAction<{ key: string; value: any }>) => {
      console.log('action.payload.key,action.payload.value', action.payload.key, action.payload.value);
      set(state, `UserGroupForm.${action.payload.key}`, action.payload.value);
    },
    doGetList: (state) => {},
    doGetActiveList: (state) => {},
    doGetGroupList: (state) => {},
    doAddGroup: (state, action: PayloadAction<{ callback: () => void }>) => {
      console.log('Yo Reached at UserAdded');
    },
    doUpdateGroup: (state, action: PayloadAction<{ callback: () => void }>) => {
      console.log('Yo Reached at UserAdded');
    },
    setUserList: (state, action: PayloadAction<Array<UserInterface>>) => {
      state.UserList = action.payload;
    },
    setActiveUserGroupList: (state, action: PayloadAction<Array<UserGroupInterface>>) => {
      state.activeUserGroupList = action.payload;
    },
    setUserGroupEdit: (state, action: PayloadAction<UserGroupInterface>) => {
      state.UserGroupForm = action.payload;
    },
    setUserGroupList: (state, action: PayloadAction<Array<UserGroupInterface>>) => {
      state.UserGroupList = action.payload;
    },
    setSelectedUserEditList: (state, action: PayloadAction<Array<UserInterface>>) => {

        state.selectedUser=action.payload;
      
    },
    setSelectedUserList: (state, action: PayloadAction<UserInterface>) => {
      if (state.selectedUser.filter((x) => x.id == action.payload.id).length > 0) {
        state.selectedUser.splice(
          state.selectedUser.findIndex((x) => x.id == action.payload.id),
          1
        );
      } else {
        state.selectedUser.push(action.payload);
      }
    },
    getGroupDataForEdit: (state, action: PayloadAction<string>) => {},
    clearGroupFrom: (state) => {
      state.selectedUser = [];
      state.UserGroupForm.status = 0;
      state.UserGroupForm.title = '';
      state.pageSize = 0;
      state.pageNo = 1;
      state.totalRow = 0;
      state.search = '';
    }
  }
});

export const { reducer, actions, name: sliceKey } = useClinicSlice;
