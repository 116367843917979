import { call, delay, put, select, takeLatest } from 'redux-saga/effects';
import { actions } from './slice';
import { openSnackbar } from 'store/reducers/snackbar';
import { AxiosResponse } from 'axios';
import { SelectPageNo, SelectPageSize, SelectSearch, selectSelectedUserId, selectUserForm, selectselectedPlan } from './selector';
import { userState } from '../types';
import { AssignPlanToUserRequest, GetActivePlanRequest, GetUserlistRequest, SaveUserRequest } from 'utils copy/request';
import CatchBlockFunction from 'hooks/CatchBlockFunction';
export function* categoryAddRequest(action: { payload: { callback: any } }) {
  yield delay(500);
  const form: userState = yield select(selectUserForm);
  let data = {
    title: form,
    description: form
  };
  try {
    const response: AxiosResponse = yield call(SaveUserRequest, data);
    if (response.data.status == false) {
      //toast.error(response.data.message);
      return;
    }
    //toast.success(response.data.message);
    yield call(action?.payload?.callback);
  } catch (error: any) {
    if (error.response) {
      if (error.response.data.valData) {
        let dataerrer = error.response.data.valData.errors.map((item: any) => {
          return { name: item.param, message: item.msg };
        });
        for (let index = 0; index < dataerrer.length; index++) {
          // const element = dataerrer[index];
          //toast.error(element.message);
        }
      } else {
        //toast.error(error.response.data.message);
      }
    } else {
      //toast.error(error.message);
    }
  }
}
export function* getUserList() {
  yield delay(500);

  try {
    const pageNo: number = yield select(SelectPageNo);
    const pageSize: number = yield select(SelectPageSize);
    const searchText: string = yield select(SelectSearch);
    const response: AxiosResponse = yield call(
      GetUserlistRequest,
      `?pageNo=${pageNo}&pageSize=${pageSize}${searchText.length > 0 ? '&search=' + searchText : ''}`
    );
    yield put(actions.setLoading(false));
    if (response.data.status == false) {
      // toast.error(response.data.message);
      return;
    }
    yield put(actions.setTotalRow(response.data.data.meta ? response.data.data.meta.total : 0));
    yield put(actions.setUserList(response.data.data.results));
  } catch (error: any) {
   CatchBlockFunction(error)
  }
}
export function* getActivePlanList() {
  yield delay(500);

  try {
    const response: AxiosResponse = yield call(GetActivePlanRequest);
    yield put(actions.setLoading(false));
    if (response.data.status == false) {
      // toast.error(response.data.message);
      return;
    }

    yield put(actions.setPlanList(response.data.data));
  } catch (error: any) {
   CatchBlockFunction(error)
  }
}
export function* AssignPlantoUserRequest() {
  yield delay(500);

  const selectedPlan: string = yield select(selectselectedPlan);
  const selectedUserId: string = yield select(selectSelectedUserId);

  if (selectedPlan.length === 0) {
    yield put(
      openSnackbar({
        open: true,
        message: 'please fill title',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: false
      })
    );

    return;
  }
  if (selectedUserId.length === 0) {
    yield put(
      openSnackbar({
        open: true,
        message: 'please fill title',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: false
      })
    );

    return;
  }

  console.log('saga working');
  let data = { userId: selectedUserId, planId: selectedPlan };

  try {
    const response: AxiosResponse = yield call(AssignPlanToUserRequest, data);
    if (response.data.status == false      ) {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: false
        })
      );

      return;
    }
    yield put(
      openSnackbar({
        open: true,
        message: response.data.message,
        variant: 'alert',
        alert: {
          color: 'success'
        },
        close: false
      })
    );

    yield put(actions.toggalModal())
    yield put(actions.clearPlanForm())
    yield put(actions.doGetList())
  } catch (error: any) {
      CatchBlockFunction(error)
  }
}
export function* userRepoSaga() {
  yield takeLatest(actions.NewUserAdded, categoryAddRequest);
  yield takeLatest(actions.doGetList, getUserList);
  yield takeLatest(actions.doGetPlanList, getActivePlanList);
  yield takeLatest(actions.doAssignPlanToUser, AssignPlantoUserRequest);
}
