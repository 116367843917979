import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '../../../utils copy/@reduxjs/toolkit';
import { UserInterface, SubscriptionReportState } from '../types';
export const initialState: SubscriptionReportState = {
  loading: false,
  pageSize: 10,
  totalRow: 0,
  search: '',
  pageNo: 1,
  List: [],   
};

export const useClinicSlice = createSlice({
  name: 'subscriptionReportState',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },
    setPageNo: (state, action: PayloadAction<number>) => {
      state.pageNo = action.payload;
    },
    setTotalRow: (state, action: PayloadAction<number>) => {
      state.totalRow = action.payload;
    },
    setSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
    },
    setUserList: (state, action: PayloadAction<Array<UserInterface>>) => {
      state.List = action.payload;
    }, 
    doGetList: (state) => {},
   
  }
});

export const { reducer, actions, name: sliceKey } = useClinicSlice;
