import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '../../../utils copy/@reduxjs/toolkit';
import { ProductInterface, ProductState } from '../types';
import { set } from 'lodash';

export const initialState: ProductState = {
  loading: false,
  pageSize: 10,
  totalRow: 0,
  search: '',
  pageNo: 1,

  editIndex: 0,
  isadd: false,
  dissableCheck: false,
  productList: [],
  productFormData: {
    id: '',
    coins: 0,
    stockCount: '',
    shortDescription: '',
    description: '',
    title: '',
    photo: ''
  }
};

export const useClinicSlice = createSlice({
  name: 'ProductState',
  initialState,
  reducers: {
    updateNewProductFormValue: (state, action: PayloadAction<{ key: string; value: any }>) => {
      set(state, `productFormData.${action.payload.key}`, action.payload.value);
    },
    NewProductAdded: (state, action: PayloadAction<{ callback: () => void }>) => {
      console.log('Yo Reached at UserAdded');
    },
    dogetDataForEdit: (state, action: PayloadAction<string>) => {},
    UpdatedProductAdded: (state, action: PayloadAction<{ callback: () => void }>) => {
      console.log('Yo Reached at UserAdded');
    },
    setSearch: (state: { search: string }, action: PayloadAction<string>) => {
      state.search = action.payload;
    },
    addImage: (state, action: PayloadAction<{ data: any; type: number; callback: any }>) => {},
    doGetProductList: (state) => {},
    clearProductFrom: (state) => {
      state.productFormData.coins =0;
      state.productFormData.description = '';
      state.productFormData.id = '';
      state.productFormData.photo = '';
      state.productFormData.shortDescription = '';
      state.productFormData.stockCount = '';
      state.productFormData.title = '';
    },
    setProductList: (state, action: PayloadAction<Array<ProductInterface>>) => {
      state.productList = action.payload;
    },

    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setTotalRow: (state, action: PayloadAction<number>) => {
      state.totalRow = action.payload;
    },
    setPageNo: (state, action: PayloadAction<number>) => {
      state.pageNo = action.payload;
    },
    setPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },
    ProductDelete: (state, action: PayloadAction<any>) => {
      // state.FaqList.splice(action.payload , 1)
    },
    ProductEdit: (state, action: PayloadAction<any>) => {
      state.productFormData = action.payload;
    }
    // International  Product API
  }
});

export const { reducer, actions, name: sliceKey } = useClinicSlice;
