import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../newTypes/RootState';
import { initialState } from './slice';

const selectDomain = (state: RootState) => {
  console.log('state.user', state);

  if (state) {
    if (state.userSate) {
      return state.subscriptionReportState;
    } else {
      return initialState;
    }
  } else {
    return initialState;
  }
};

export const selectList = createSelector([selectDomain], (state) => state.List);
export const SelectLoading = createSelector(
  [selectDomain],
  (state) => state.loading
);
export const SelectPageNo = createSelector(
  [selectDomain],
  (state) => state.pageNo
);
export const SelectPageSize = createSelector(
  [selectDomain],
  (state) => state.pageSize
);
export const SelectTotalRow = createSelector(
  [selectDomain],
  (state) => state.totalRow
);
export const SelectSearch = createSelector(
  [selectDomain],
  (state) => state.search
);


