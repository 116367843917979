import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '../../../utils copy/@reduxjs/toolkit';
import { MyOrderInterface, MyOrderState } from '../types';

export const initialState: MyOrderState = {
  loading: false,
  pageSize: 10,
  totalRow: 0,
  search: '',
  pageNo: 1,
  isadd: false,

  List: [],
  orderDate: ''
};

export const useClinicSlice = createSlice({
  name: 'myOrder',
  initialState,
  reducers: {
    doGetList: (state: any) => {},
    setList: (state, action: PayloadAction<Array<MyOrderInterface>>) => {
      state.List = action.payload;
    },
    setLoading: (state: { loading: boolean }, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setPageSize: (state: { pageSize: number }, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },
    setorderDate: (state, action: PayloadAction<any>) => {
      state.orderDate = action.payload;
    },
    clearorderDate: (state) => {
      state.orderDate = '';
    },
    setPageNo: (state: { pageNo: number }, action: PayloadAction<number>) => {
      state.pageNo = action.payload;
    },
    setTotalRow: (state: { totalRow: number }, action: PayloadAction<number>) => {
      state.totalRow = action.payload;
    },
    setSearch: (state: { search: string }, action: PayloadAction<string>) => {
      state.search = action.payload;
    }
  }
});

export const { reducer, actions, name: sliceKey } = useClinicSlice;
