import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '../../../utils copy/@reduxjs/toolkit';
import { MyOrderInterface, ReportsState, TeacherListInterface } from '../types';
import { set } from 'lodash';

export const initialState: ReportsState = {
  loading: false,
  pageSize: 10,
  totalRow: 0,
  search: '',
  pageNo: 1,
  isadd: false,
  List: [],
  TeacherList: [],
  teacherloading: false,
  teacherisadd: false,
  teacherpageNo: 1,
  teacherpageSize: 10,
  teachertotalRow: 0,
  teachersearch: '',
  filterclassList: [],
  filterteacherList: [],
  listing: [],
  filter: {
    selectedTechaerFilter: [],
    selectedClassFilter: []
  }
};

export const useClinicSlice = createSlice({
  name: 'ReportsState',
  initialState,
  reducers: {
    doGetList: (state: ReportsState) => {},
    setList: (state, action: PayloadAction<Array<MyOrderInterface>>) => {
      state.List = action.payload;
    },
    setFilterTeacherList: (state, action: PayloadAction<[]>) => {
      state.filterteacherList = action.payload;
    },
    setfilterclassList: (state, action: PayloadAction<[]>) => {
      state.filterclassList = action.payload;
    },
    ClearFilter: (state) => {
      state.filter.selectedClassFilter = [];
      state.filter.selectedTechaerFilter = [];
    },
    setLoading: (state: { loading: boolean }, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setPageSize: (state: { pageSize: number }, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },
    setPageNo: (state: { pageNo: number }, action: PayloadAction<number>) => {
      state.pageNo = action.payload;
    },
    setTotalRow: (state: { totalRow: number }, action: PayloadAction<number>) => {
      state.totalRow = action.payload;
    },
    setSearch: (state: { search: string }, action: PayloadAction<string>) => {
      state.search = action.payload;
    },

    updateReportFormValue: (state, action: PayloadAction<{ key: string; value: any }>) => {
      set(state, `filter.${action.payload.key}`, action.payload.value);
    },
    DeleteCleanandSafetyMeasureList: (state, action: PayloadAction<any>) => {
      console.log(action.payload, 'DeleteFaqModalList');
      state.filter.selectedClassFilter.splice(
        state.filter.selectedClassFilter.findIndex((obj) => obj === action.payload),
        1
      );
    },
    DeleteTeacherFilterList: (state, action: PayloadAction<any>) => {
      console.log(action.payload, 'DeleteFaqModalList');
      state.filter.selectedTechaerFilter.splice(
        state.filter.selectedTechaerFilter.findIndex((obj) => obj === action.payload),
        1
      );
    },
    doGetTeacherList: (state: any) => {},
    doGetAllFilterTeacherList: (state: any) => {},
    doGetAllClassList: (state: any) => {},
    setTeacherList: (state, action: PayloadAction<Array<TeacherListInterface>>) => {
      state.TeacherList = action.payload;
    },
    setTeacherLoading: (state: ReportsState, action: PayloadAction<boolean>) => {
      state.teacherloading = action.payload;
    },
    setTeacherPageSize: (state, action: PayloadAction<number>) => {
      state.teacherpageSize = action.payload;
    },
    setTeacherPageNo: (state, action: PayloadAction<number>) => {
      state.teacherpageNo = action.payload;
    },
    setTeacherTotalRow: (state, action: PayloadAction<number>) => {
      state.teachertotalRow = action.payload;
    },
    setTeacherSearch: (state, action: PayloadAction<string>) => {
      state.teachersearch = action.payload;
    }
  }
});

export const { reducer, actions, name: sliceKey } = useClinicSlice;
