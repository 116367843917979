import { AxiosResponse } from 'axios';
import { call, delay, put, select, takeLatest } from 'redux-saga/effects';
import {
  SelectCoinStudentPageNo,
  SelectCoinStudentPageSize,
  SelectPageNo,
  SelectPageSize,
  SelectSearch,
  SelectStudentPageNo,
  SelectStudentPageSize,
  SelectTeacherAddForm,
  SelectTeacherDetailPageNo,
  SelectTeacherDetailPageSize,
  SelectTeacherPageNo,
  SelectTeacherPageSize,
  SelectTeacherSearch,
  selectStudentAddCoinsForm
} from './selector';
import { actions } from './slice';
import {
  DeleteCategoryRequest,
  doAddCoinsTeacher,
  doAddTeacher,
  doGetAllCoinStuedntDetailList,
  doGetAllStuedntDetailList,
  doGetAllTeacherDetailList,
  doGetAllTeacherList,
  GetCategoryEditRequest,
  UpdateCategoryRequest
} from 'utils copy/request';
import { StudentAddCoinInterface, TeacherAddInterface } from '../types';
import { openSnackbar } from 'store/reducers/snackbar';
import CatchBlockFunction from 'hooks/CatchBlockFunction';
import { FilterInterface } from 'pages/Report/types';
import { selectReportPageFilter } from 'pages/Report/redux/selector';

export function* doGetAllTeacherListRequest() {
  yield delay(500);

  try {
    const pageNo: number = yield select(SelectPageNo);
    const pageSize: number = yield select(SelectPageSize);
    const filter: FilterInterface = yield select(selectReportPageFilter);
    const searchText: string = yield select(SelectSearch);
    const response: AxiosResponse = yield call(
      doGetAllTeacherList,
      `?pageNo=${pageNo}&pageSize=${pageSize}${searchText.length > 0 ? '&search=' + searchText : ''}&classFilter=${
        filter.selectedClassFilter
      }`
    );
    yield put(actions.setLoading(false));
    if (response.data.status == false) {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true
        })
      );
      return;
    }
    console.log(response, 'response');

    yield put(actions.setTotalRow(response.data[0].meta ? response.data[0].meta.total : 0));
    yield put(actions.setList(response.data[0].results));
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* doTeacherAddRequest(action: { payload: { callback: any } }) {
  yield delay(500);

  const form: TeacherAddInterface = yield select(SelectTeacherAddForm);
  if (form.firstName.length === 0) {
    yield put(
      openSnackbar({
        open: true,
        message: 'please fill first name',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      })
    );

    return;
  }
  if (form.lastName.length === 0) {
    yield put(
      openSnackbar({
        open: true,
        message: 'please fill last name',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      })
    );

    return;
  }

  if (form.email.length === 0) {
    yield put(
      openSnackbar({
        open: true,
        message: 'please fill email',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      })
    );

    return;
  }
  if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(form.email)) {
    yield put(
      openSnackbar({
        open: true,
        message: 'please enter valid email',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      })
    );
    return;
  }
  if (form.password.length === 0) {
    yield put(
      openSnackbar({
        open: true,
        message: 'please fill password',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      })
    );

    return;
  }

  if (form.mobileNumber == 0) {
    yield put(
      openSnackbar({
        open: true,
        message: 'please fill mobileNumber',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      })
    );

    return;
  }

  if (form.mobileNumber.toString().length != 10) {
    yield put(
      openSnackbar({
        open: true,
        message: 'please Enter Valid mobileNumber',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      })
    );

    return;
  }

  if (form.zip != 0) {
    if (form.zip.toString().length != 6) {
      yield put(
        openSnackbar({
          open: true,
          message: 'Enter Valid ZIP Code',
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true
        })
      );

      return;
    }
  }
  if (form.class.length === 0) {
    yield put(
      openSnackbar({
        open: true,
        message: 'please fill class',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      })
    );

    return;
  }
  if (form.section.length === 0) {
    yield put(
      openSnackbar({
        open: true,
        message: 'please fill section',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      })
    );

    return;
  }
  try {
    const response: AxiosResponse = yield call(doAddTeacher, form);
    if (response.data.status == false) {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true
        })
      );
      return;
    }
    yield put(
      openSnackbar({
        open: true,
        message: response.data.message,
        variant: 'alert',
        alert: {
          color: 'success'
        },
        close: true
      })
    );

    yield call(action?.payload?.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* deleteTeacherRequest(action: { payload: { id: string; callback: any } }) {
  yield delay(500);
  if (action.payload.id.length === 0) {
    yield put(
      openSnackbar({
        open: true,
        message: 'Select Story to delete',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      })
    );
    return;
  }

  try {
    const response: AxiosResponse = yield call(DeleteCategoryRequest, action.payload.id);
    if (response.data.status == false) {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true
        })
      );

      return;
    }
    yield put(
      openSnackbar({
        open: true,
        message: response.data.message,
        variant: 'alert',
        alert: {
          color: 'success'
        },
        close: true
      })
    );
    yield call(action?.payload?.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* getEditRequest(action: { payload: string }) {
  yield delay(500);

  try {
    const response: AxiosResponse = yield call(GetCategoryEditRequest, action.payload);
    if (response.data.status == false) {
      return;
    }
    yield put(actions.getDataForEdit(response.data.data[0]));
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* UpdateRequest(action: { payload: { callback: any } }) {
  yield delay(500);

  const form: TeacherAddInterface = yield select(SelectTeacherAddForm);
  if (form.firstName.length === 0) {
    yield put(
      openSnackbar({
        open: true,
        message: 'please fill first name',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      })
    );

    return;
  }
  if (form.lastName.length === 0) {
    yield put(
      openSnackbar({
        open: true,
        message: 'please fill last name',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      })
    );

    return;
  }

  if (form.email.length === 0) {
    yield put(
      openSnackbar({
        open: true,
        message: 'please fill email',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      })
    );

    return;
  }
  if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(form.email)) {
    yield put(
      openSnackbar({
        open: true,
        message: 'please enter valid email',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      })
    );
    return;
  }
  if (form.mobileNumber == 0) {
    yield put(
      openSnackbar({
        open: true,
        message: 'please fill mobileNumber',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      })
    );

    return;
  }

  if (form.mobileNumber.toString().length != 10) {
    yield put(
      openSnackbar({
        open: true,
        message: 'please Enter Valid mobileNumber',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      })
    );

    return;
  }

  if (form.class.length === 0) {
    yield put(
      openSnackbar({
        open: true,
        message: 'please fill class',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      })
    );

    return;
  }
  if (form.zip != 0) {
    if (form.zip.toString().length != 6) {
      yield put(
        openSnackbar({
          open: true,
          message: 'Enter Valid ZIP Code',
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true
        })
      );

      return;
    }
  }

  try {
    const response: AxiosResponse = yield call(UpdateCategoryRequest, form);
    if (response.data.status == false) {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true
        })
      );

      return;
    }
    yield put(
      openSnackbar({
        open: true,
        message: response.data.message,
        variant: 'alert',
        alert: {
          color: 'success'
        },
        close: true
      })
    );

    yield call(action?.payload?.callback);
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* doGetAllTeacherReportListRequest() {
  yield delay(500);
  try {
    const pageNo: number = yield select(SelectTeacherPageNo);
    const pageSize: number = yield select(SelectTeacherPageSize);
    const searchText: string = yield select(SelectTeacherSearch);
    const filter: FilterInterface = yield select(selectReportPageFilter);
    const response: AxiosResponse = yield call(
      doGetAllTeacherList,
      `?pageNo=${pageNo}&pageSize=${pageSize}${searchText.length > 0 ? '&search=' + searchText : ''}&classFilter=${
        filter.selectedClassFilter
      }`
    );

    yield put(actions.setTeacherLoading(false));
    if (response.data.status == false) {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true
        })
      );
      return;
    }

    yield put(actions.setTeacherTotalRow(response.data[0].meta ? response.data[0].meta.total : 0));
    yield put(actions.setTeacherList(response.data[0].results));
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* doGetTeacherDetailListRequest(action: {
  payload: {
    id: any;
    callback: any;
  };
}) {
  yield delay(500);
  try {
    const pageNo: number = yield select(SelectTeacherDetailPageNo);
    const pageSize: number = yield select(SelectTeacherDetailPageSize);
    const response: AxiosResponse = yield call(doGetAllTeacherDetailList, action.payload.id, `?pageNo=${pageNo}&pageSize=${pageSize}`);

    yield put(actions.setTeacherLoading(false));
    if (response.data.status == false) {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true
        })
      );
      return;
    }

    yield put(actions.setTeacherDetailTotalRow(response.data.data[0].meta ? response.data.data[0].meta.total : 0));
    yield put(actions.setTeacherDetailList(response.data.data[0].results));
    yield call(action?.payload?.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* doAddCoinsRequest(action: { payload: { id: string; callback: any } }) {
  yield delay(500);
  const form: StudentAddCoinInterface = yield select(selectStudentAddCoinsForm);

  if (form.coins === 0) {
    yield put(
      openSnackbar({
        open: true,
        message: 'please fill Coins',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      })
    );
    return;
  }
  if (form.coins > 0) {
    if (form.comment.length === 0) {
      yield put(
        openSnackbar({
          open: true,
          message: 'please Enter Comment',
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true
        })
      );
      return;
    }
  }

  try {
    const response: AxiosResponse = yield call(doAddCoinsTeacher, form, action.payload.id);
    if (response.data.status == false) {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true
        })
      );

      return;
    }
    yield put(
      openSnackbar({
        open: true,
        message: response.data.message,
        variant: 'alert',
        alert: {
          color: 'success'
        },
        close: true
      })
    );

    yield call(action?.payload?.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* doGetStudentDetailListRequest(action: {
  payload: {
    id: any;
    callback: any;
  };
}) {
  yield delay(500);
  try {
    const pageNo: number = yield select(SelectStudentPageNo);
    const pageSize: number = yield select(SelectStudentPageSize);
    const response: AxiosResponse = yield call(doGetAllStuedntDetailList, action.payload.id, `?pageNo=${pageNo}&pageSize=${pageSize}`);
    yield put(actions.setTeacherLoading(false));
    if (response.data.status == false) {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true
        })
      );
      return;
    }
    yield put(actions.setStudentDetailTotalRow(response.data.data[0].meta ? response.data.data[0].meta.total : 0));
    yield put(actions.setStudentDetailList(response.data.data[0].results));
    yield call(action?.payload?.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* dogetByIdCoinDetailStudentRequest(action: {
  payload: {
    id: any;
    callback: any;
  };
}) {
  yield delay(500);
  try {
    const pageNo: number = yield select(SelectCoinStudentPageNo);
    const pageSize: number = yield select(SelectCoinStudentPageSize);
    const response: AxiosResponse = yield call(doGetAllCoinStuedntDetailList, action.payload.id, `?pageNo=${pageNo}&pageSize=${pageSize}`);
    if (response.data.status == false) {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true
        })
      );
      return;
    }
    yield put(actions.setCoinStudentDetailTotalRow(response.data.data[0].meta ? response.data.data[0].meta.total : 0));
    yield put(actions.setCoinStudentDetailList(response.data.data));
    yield call(action?.payload?.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* CategoryRepoSaga() {
  yield takeLatest(actions.doAddTeacher, doTeacherAddRequest);
  yield takeLatest(actions.doGetTeacher, doGetAllTeacherListRequest);
  yield takeLatest(actions.doTeacherDelete, deleteTeacherRequest);
  yield takeLatest(actions.dogetDataForEdit, getEditRequest);
  yield takeLatest(actions.doUpdate, UpdateRequest);
  yield takeLatest(actions.doGetTeacherList, doGetAllTeacherReportListRequest);
  yield takeLatest(actions.dogetByIdTeacher, doGetTeacherDetailListRequest);
  yield takeLatest(actions.dogetByIdStudent, doGetStudentDetailListRequest);
  yield takeLatest(actions.doAddCoinBYId, doAddCoinsRequest);
  yield takeLatest(actions.dogetByIdCoinDetailStudent, dogetByIdCoinDetailStudentRequest);
}
