import { AxiosResponse } from 'axios';
import CatchBlockFunction from 'hooks/CatchBlockFunction';
import { call, delay, put, select, takeLatest } from 'redux-saga/effects';
import { openSnackbar } from 'store/reducers/snackbar';
import { getStudents, LoginRequest, MeProfileRequest } from 'utils copy/request';
import { RolesEnum } from '../types';
import { selectLoginForm } from './selector';
import { actions } from './slice';

export function* meProfileRequest() {
  yield delay(500);
  if (localStorage.getItem('token')) {
    try {
      const response: AxiosResponse = yield call(MeProfileRequest);
      if (response && !response.status) {
        openSnackbar({
          open: true,
          message: 'response.message',
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: false
        });
        return;
      }

      yield put(
        actions.setProfileData({
          id: response.data.id,
          firstName: response.data.firstName,
          lastName: response.data.lastName,
          email: response.data.email,
          usertype: response.data.role,
          class: response.data.class,
          section: response.data.section
        })
      );

      yield put(actions.setisLogin(true));
    } catch (error: any) {
      CatchBlockFunction(error);
    }
  } else {
    yield put(actions.setisLogin(false));
  }
}
export function* meLogOutRequest() {
  yield delay(500);
  localStorage.removeItem('token');
  yield put(actions.setisLogin(false));
  yield put(
    actions.setProfileData({
      id: '',
      firstName: '',
      lastName: '',
      email: '',
      usertype: RolesEnum.ADMIN,
      class: '',
      section: ''
    })
  );
}
export function* loginRequest(action: { payload: { callback: any } }) {
  yield delay(500);

  const { username, password, id } = yield select(selectLoginForm);
  const isStudent = process.env.REACT_APP_IS_STUDENT || 'false';
  let data = {
    email: username,
    password: password
  };
  let data1 = {
    password: password,
    id: id
  };
  if (isStudent === 'true') {
    if (id.length === 0) {
      yield put(
        openSnackbar({
          open: true,
          message: 'please select student',
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true
        })
      );
      return;
    }
  }
  if (isStudent === 'false') {
    if (username.length === 0) {
      yield put(
        openSnackbar({
          open: true,
          message: 'please enter username',
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true
        })
      );
      return;
    }
  }

  if (password.length === 0) {
    yield put(
      openSnackbar({
        open: true,
        message: 'please enter password',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      })
    );
    return;
  }
  try {
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(LoginRequest, isStudent === 'true' ? data1 : data);
    yield put(actions.setLoading(false));
    if (response.data.status === false) {
      console.log(response.data.message);
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true
        })
      );
      return;
    }

    if (isStudent === 'true') {
      if (response.data.data.role !== RolesEnum.STUDENT) {
        yield put(
          openSnackbar({
            open: true,
            message: 'UnAuthorized Access',
            variant: 'alert',
            alert: {
              color: 'error'
            },
            close: true
          })
        );
        return;
      }
    } else {
      if (response.data.data.role === RolesEnum.STUDENT) {
        yield put(
          openSnackbar({
            open: true,
            message: 'UnAuthorized Access',
            variant: 'alert',
            alert: {
              color: 'error'
            },
            close: true
          })
        );
        return;
      }
    }
    yield put(
      actions.setProfileData({
        id: response.data.data.id,
        firstName: '',
        lastName: '',
        email: '',
        class: '',
        section: '',
        usertype: response.data.data.role
      })
    );

    yield put(actions.setUpdateTocken(response.data.data.token));
    yield call(action?.payload?.callback());
    yield put(actions.getUserProfile());
    yield put(actions.loginSuccess());
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doGetStudentRequest(action: { payload: { callback: any } }) {
  yield delay(500);

  const { mobileNumber } = yield select(selectLoginForm);

  let data = {
    mobileNumber: Number(mobileNumber)
  };
  if (mobileNumber.length === 0) {
    yield put(
      openSnackbar({
        open: true,
        message: 'please enter mobile number',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      })
    );
    return;
  }
  if (mobileNumber.length < 10) {
    yield put(
      openSnackbar({
        open: true,
        message: 'please enter valid mobile number',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      })
    );
    return;
  }

  try {
    yield put(actions.setButtonLoading(true));
    const response: AxiosResponse = yield call(getStudents, data);
    yield put(actions.setButtonLoading(false));
    if (response.data.status === false) {
      console.log(response.data.message);
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true
        })
      );
      return;
    }
    yield put(actions.setStudents(response.data));
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setButtonLoading(false));
    CatchBlockFunction(error);
  }
}
export function* authRepoSaga() {
  yield takeLatest(actions.doLogin, loginRequest);
  yield takeLatest(actions.getUserProfile, meProfileRequest);
  yield takeLatest(actions.doLogOut, meLogOutRequest);
  yield takeLatest(actions.doGetStudents, doGetStudentRequest);
}
