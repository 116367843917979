// import { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "../../../utils copy/@reduxjs/toolkit";
import { RevenueState } from "../types";
export const initialState: RevenueState = {
  loading: false,
  pageSize: 10,
  totalRow: 0,
  search: "",
  pageNo: 1,
  editIndex: 0,
  isadd: false,
  RevenueList: []
};

export const useClinicSlice = createSlice({
  name: "RevenueState",
  initialState,
  reducers: {

  }
});

export const { reducer, actions, name: sliceKey } = useClinicSlice;
