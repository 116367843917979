import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../newTypes/RootState';
import { initialState } from './slice';

const selectDomain = (state: RootState) => {
  if (state) {
    return state.studentState;
  } else {
    return initialState;
  }
};
export const selectList = createSelector([selectDomain], (state) => state.List);
export const SelectLoading = createSelector([selectDomain], (state) => state.loading);
export const SelectPageNo = createSelector([selectDomain], (state) => state.pageNo);
export const SelectPageSize = createSelector([selectDomain], (state) => state.pageSize);
export const SelectTotalRow = createSelector([selectDomain], (state) => state.totalRow);
export const SelectDetailsPageNo = createSelector([selectDomain], (state) => state.detailsPageNo);
export const SelectDetailsPageSize = createSelector([selectDomain], (state) => state.detailsPageSize);
export const SelectDetailsTotalRow = createSelector([selectDomain], (state) => state.detailsTotalRow);
export const SelectSearch = createSelector([selectDomain], (state) => state.search);
export const SelectFrom = createSelector([selectDomain], (state) => state.Form);
export const selectActiveFilterList = createSelector([selectDomain], (state) => state.activeList);
export const SelectStudentAddForm = createSelector([selectDomain], (state) => state.studentAddForm);
export const selectStudentAddList = createSelector([selectDomain], (state) => state.studentAddList);
export const selectStudentAddCoinsForm = createSelector([selectDomain], (state) => state.studentAddCoinsForm);
export const selectStudentDetails = createSelector([selectDomain], (state) => state.StudentDetails);
export const SelectStudenttype = createSelector([selectDomain], (state) => state.studenttype);
