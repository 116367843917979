import Api from './Api';
const LoginRequest = async (data: any) => {
  return Api.post(`/admin/login`, data);
};

const getStudents = async (data: any) => {
  return Api.post(`admin/check/student/list`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const MeProfileRequest = async () => {
  return Api.get(`admin/me`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doAddTeacher = async (data: any) => {
  return Api.post(`teacher/add`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};

const doGetAllTeacherList = async (filter: string) => {
  return Api.get(`teacher/getall/${filter}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doGetAllTeacherDetailList = async (id: string, filter: string) => {
  return Api.get(`teacher/get-detail/${id}${filter}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doGetAllStuedntDetailList = async (id: string, filter: string) => {
  return Api.get(`teacher/get-student-list/${id}${filter}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const UpdateSchoolPasswordRequest = async (data: any) => {
  return Api.put(`/student/update-password`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doGetAllCoinStuedntDetailList = async (id: string, filter: string) => {
  return Api.get(`teacher/get-coins-detail/${id}${filter}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doAddStudent = async (data: any) => {
  return Api.post(`student/add`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doGetStudentList = async (filter: string) => {
  return Api.get(`student/getall/${filter}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doGetStudentEdit = async (id: string) => {
  return Api.get(`/student/get/${id}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doUpdateStudent = async (data: any) => {
  return Api.post(`/student/update/${data.id}`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doDeleteStudent = async (id: string) => {
  return Api.delete(`/student/delete/${id}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doAddCoinsStudent = async (data: any, id: string) => {
  return Api.put(`/teacher/add-coins/${id}`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doGetStudentDetailsList = async (id: string, filter: string) => {
  return Api.get(`/student/get-detail/${id}${filter}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doAddCoinsTeacher = async (data: any, id: any) => {
  return Api.put(`/admin/add-coins/${id}`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
//*******Add To Cart Start*********//
const doDeleteFromCart = async (id: string) => {
  return Api.delete(`/student/delete-cart-item/${id}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doAddToCart = async (data: any) => {
  return Api.post(`/student/add-to-cart`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doUpdateQuentiy = async (data: any) => {
  return Api.post(`/student/update-cart`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doGetAddToCartList = async () => {
  return Api.get(`/student/get-cart-detail`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doProductBooking = async () => {
  return Api.post(
    `/booking/add`,
    {},
    {
      headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
    }
  );
};
const doGetOrderList = async (filter: string) => {
  return Api.get(`student/get-all-orders/${filter}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doGetStudentOrderList = async (filter: string) => {
  return Api.get(`student/get-student-report/${filter}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doGetTeacherRequestList = async (filter: string) => {
  return Api.get(`teacher/get-teacher-report/${filter}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
//*******Add To Cart End*********//

///**********///
const GetUserlistRequest = async (filter: string) => {
  return Api.post(
    `user/admin/all/user${filter}`,
    {},
    {
      headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
    }
  );
};
const doGetTeacherAllRequestList = async (filter: string) => {
  return Api.get(`teacher/get-all-teacher-list/${filter}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const SaveUserRequest = async (data: any) => {
  return Api.post(`faq/add`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const UpdateUserRequest = async (data: any) => {
  return Api.put(`faq/update`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const GetUserEditRequest = async (id: string) => {
  return Api.get(`faq/${id}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const GetStroylistRequest = async (filter: string) => {
  return Api.post(
    `story/getall${filter}`,
    {},
    {
      headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
    }
  );
};

const GetDashboardlistRequest = async (filter: string) => {
  return Api.get(`admin/dashboard${filter}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const GetSettingFromRequest = async () => {
  return Api.get(`setting/get/setting/data`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};

const SaveStroyRequest = async (data: any) => {
  return Api.post(`story/add`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const UpdateStroyRequest = async (data: any) => {
  return Api.patch(`story/edit`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const UpdateStroyOnlyRequest = async (data: any) => {
  return Api.patch(`story/edit/only`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const GetStroyEditRequest = async (id: string) => {
  return Api.get(`faq/${id}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const GetCommunityStroylistRequest = async (filter: string) => {
  return Api.post(
    `story/community/getall${filter}`,
    {},
    {
      headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
    }
  );
};
const DeleteCommunityStroyRequest = async (id: string) => {
  return Api.delete(`story/communitydelete/${id}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};

const SaveSettingRequest = async (data: any) => {
  return Api.post(`setting/add/parameter`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const GetStoryEditRequest = async (id: string) => {
  return Api.get(`story/getbyid/${id}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const GetCommunityStoryEditRequest = async (id: string) => {
  return Api.get(`story/get/community/story/${id}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const GetPromptEditRequest = async (id: string) => {
  return Api.get(`story/prompts/getbyid/${id}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const GetStroyPromptlistRequest = async (filter: string) => {
  return Api.post(
    `story/storyprompts/getall${filter}`,
    {},
    {
      headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
    }
  );
};
const SavePromptsRequest = async (data: any) => {
  return Api.post(`story/save/stroyprompts`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const UpdatePromptsRequest = async (data: any) => {
  return Api.patch(`story/editprompts`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const GetStroyTreeFileUplodRequest = async (data: any, onProgress: any) => {
  return Api.patch(`story/file/add`, data, {
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
      'Content-Type': 'multipart/form-data'
    },
    onUploadProgress: onProgress
  });
};
const GetStroyDynamiclistRequest = async (filter: string) => {
  return Api.post(
    `story/dynamic/getall${filter}`,
    {},
    {
      headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
    }
  );
};
const GetCodotStroylistRequest = async () => {
  return Api.get(`story/codot/getall`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};

const GetCodotTextStroylistRequest = async () => {
  return Api.get(`story/text/getall`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const SaveDynamicStoryRequest = async (data: any) => {
  return Api.post(`story/dynamic/add`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const UpdateDynamicStoryRequest = async (data: any) => {
  return Api.patch(`story/dynamic/edit`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const GetDynamicEditRequest = async (id: string) => {
  return Api.get(`story/dynamic/getbyid/${id}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const GetUserlistForGroupRequest = async (filter: string) => {
  return Api.get(`user/admin/all/for-user-group${filter}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const SaveUserGroupRequest = async (data: any) => {
  return Api.post(`user/create/user/group`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const GetUserGrouplistRequest = async (filter: string) => {
  return Api.get(`user/admin/user-group/all${filter}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const GetGroupEditRequest = async (id: string) => {
  return Api.get(`user/get/user-group/admin/${id}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const UpdateUserGroupRequest = async (data: any) => {
  return Api.patch(`user/update/user/group`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const GetActiveUserGroupListRequest = async () => {
  return Api.get(`user/get/user-group/active`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const SaveDiscountRequest = async (data: any) => {
  return Api.post(`discount/add`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const SaveFIlterRequest = async (data: any) => {
  return Api.post(`filters/add`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};

const GetDiscountlistRequest = async (filter: string) => {
  return Api.get(`discount/getall${filter}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};

const GetActiveFilterlistRequest = async () => {
  return Api.get(`filters/getactive`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const GetAllCategorylistRequest = async (filter: string) => {
  return Api.get(`admin/category/getall${filter}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};

const GetAllChildlistRequest = async () => {
  return Api.get(`admin/category/get/child-list`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const DeleteCategoryRequest = async (id: string) => {
  return Api.delete(`/teacher/delete/${id}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const GetDiscountEditRequest = async (id: string) => {
  return Api.get(`discount/getbyid/${id}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};

const GetCategoryEditRequest = async (id: string) => {
  return Api.get(`/teacher/get/${id}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const UpdateDiscountRequest = async (data: any) => {
  return Api.patch(`discount/edit`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};

const SaveSubscriptionRequest = async (data: any) => {
  return Api.post(`subscription/add`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const GetSubscriptionlistRequest = async (filter: string) => {
  return Api.get(`subscription/getall${filter}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const GetSubscriptionEditRequest = async (id: string) => {
  return Api.get(`subscription/getbyid/${id}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const UpdateSubscriptionRequest = async (data: any) => {
  return Api.patch(`subscription/edit`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const GetActivePlanRequest = async () => {
  return Api.get(`subscription/getall/active`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const AssignPlanToUserRequest = async (data: any) => {
  return Api.post(`order/add/subscription/order/manual`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const GetSubscriptionReportRequest = async (filter: string) => {
  return Api.get(`order/get/subscription${filter}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const GetDiscountReportRequest = async (filter: string) => {
  return Api.get(`order/get/discount${filter}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const GetStoryReportRequest = async (filter: string) => {
  return Api.get(`story/get/report${filter}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const GetStoryExportReportRequest = async (filter: string) => {
  return Api.post(
    `story/export${filter}`,
    {},
    {
      headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
    }
  );
};
const UpdateStroyStatusRequest = async (data: any) => {
  return Api.patch(`story/update/status`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};

const UpdateCategoryRequest = async (data: any) => {
  return Api.post(`teacher/update/${data.id}`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const UpdateCategoryStatusRequest = async (data: any) => {
  return Api.patch(`admin/category/update/status`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const DeleteProductRequest = async (markupId: string) => {
  return Api.delete(`product/delete/${markupId}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const GetProductlist = async (filter: string) => {
  return Api.get(`product/get-all${filter}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const SaveProductRequest = async (data: any) => {
  return Api.post(`product/add`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};

const uplodeImageRequest = async (data: any) => {
  return Api.put(`product/upload-image`, data, {
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
      'Content-Type': 'multipart/form-data'
    }
  });
};

const DeleteCategoryNewRequest = async (markupId: string) => {
  return Api.delete(`/category/delete/${markupId}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const GetCategorylistRequest = async (filter: string) => {
  return Api.get(`category/get-all${filter}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const SaveCategoryRequest = async (data: any) => {
  return Api.post(`category/add`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const UpdatedCategoryRequest = async (data: any) => {
  return Api.post(`category/update/${data.id}`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const GetCategoryNewEditRequest = async (id: string) => {
  return Api.get(`category/get/${id}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const GetProductNewEditRequest = async (id: string) => {
  return Api.get(`product/get/${id}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const UpdatedProductRequest = async (data: any) => {
  return Api.post(`/product/update/${data.id}`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doGetTeacherFilterList = async () => {
  return Api.get(`/teacher/get-all-list`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doGetAllClassFilterList = async () => {
  return Api.get(`/teacher/get-all-class `, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
export {
  doGetAllClassFilterList,
  doGetTeacherFilterList,
  UpdatedProductRequest,
  GetProductNewEditRequest,
  GetCategoryNewEditRequest,
  UpdatedCategoryRequest,
  SaveCategoryRequest,
  GetCategorylistRequest,
  DeleteCategoryNewRequest,
  uplodeImageRequest,
  SaveProductRequest,
  GetProductlist,
  DeleteProductRequest,
  LoginRequest,
  MeProfileRequest,
  doAddTeacher,
  doGetAllTeacherList,
  doAddStudent,
  doGetStudentList,
  doGetStudentEdit,
  doUpdateStudent,
  doDeleteStudent,
  doAddToCart,
  doGetAddToCartList,
  doProductBooking,
  doGetStudentOrderList,
  doAddCoinsStudent,
  doGetStudentDetailsList,
  //********** */
  GetStoryExportReportRequest,
  UpdateCategoryRequest,
  GetCategoryEditRequest,
  DeleteCategoryRequest,
  GetAllCategorylistRequest,
  SaveFIlterRequest,
  doDeleteFromCart,
  GetUserlistRequest,
  SaveUserRequest,
  UpdateUserRequest,
  GetUserEditRequest,
  GetStroylistRequest,
  SaveStroyRequest,
  UpdateStroyRequest,
  GetStroyEditRequest,
  GetCommunityStroylistRequest,
  DeleteCommunityStroyRequest,
  SaveSettingRequest,
  GetSettingFromRequest,
  GetStoryEditRequest,
  GetPromptEditRequest,
  GetStroyPromptlistRequest,
  SavePromptsRequest,
  UpdateSchoolPasswordRequest,
  doAddCoinsTeacher,
  UpdatePromptsRequest,
  GetCommunityStoryEditRequest,
  GetStroyTreeFileUplodRequest,
  GetStroyDynamiclistRequest,
  GetCodotStroylistRequest,
  GetCodotTextStroylistRequest,
  SaveDynamicStoryRequest,
  UpdateDynamicStoryRequest,
  GetDynamicEditRequest,
  GetUserlistForGroupRequest,
  SaveUserGroupRequest,
  GetUserGrouplistRequest,
  GetGroupEditRequest,
  UpdateUserGroupRequest,
  GetActiveUserGroupListRequest,
  SaveDiscountRequest,
  GetDiscountlistRequest,
  GetDiscountEditRequest,
  UpdateDiscountRequest,
  SaveSubscriptionRequest,
  GetSubscriptionlistRequest,
  GetSubscriptionEditRequest,
  UpdateSubscriptionRequest,
  GetActivePlanRequest,
  AssignPlanToUserRequest,
  GetSubscriptionReportRequest,
  GetDiscountReportRequest,
  GetStoryReportRequest,
  UpdateStroyStatusRequest,
  GetAllChildlistRequest,
  UpdateStroyOnlyRequest,
  GetActiveFilterlistRequest,
  UpdateCategoryStatusRequest,
  doGetTeacherRequestList,
  GetDashboardlistRequest,
  doGetOrderList,
  doGetTeacherAllRequestList,
  doGetAllTeacherDetailList,
  doGetAllStuedntDetailList,
  doGetAllCoinStuedntDetailList,
  doUpdateQuentiy,
  getStudents
};
