import { call, delay, put, select, takeLatest } from 'redux-saga/effects';
import { actions } from './slice';
import { AxiosResponse } from 'axios';
import { SelectPageNo, SelectPageSize, SelectSearch } from './selector';

import { GetDiscountReportRequest } from 'utils copy/request';
import CatchBlockFunction from 'hooks/CatchBlockFunction';

export function* getUserList() {
  yield delay(500);

  try {
    const pageNo: number = yield select(SelectPageNo);
    const pageSize: number = yield select(SelectPageSize);
    const searchText: string = yield select(SelectSearch);
    const response: AxiosResponse = yield call(
      GetDiscountReportRequest,
      `?pageNo=${pageNo}&pageSize=${pageSize}${searchText.length > 0 ? '&search=' + searchText : ''}`
    );
    yield put(actions.setLoading(false));
    if (response.data.status == false) {
      // toast.error(response.data.message);
      return;
    }
    yield put(actions.setTotalRow(response.data.data.meta ? response.data.data.meta.total : 0));
    yield put(actions.setUserList(response.data.data.results));
  } catch (error: any) {
   CatchBlockFunction(error)
  }
}

export function* discountReportRepoSaga() {
  yield takeLatest(actions.doGetList, getUserList);
}
