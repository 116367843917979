export interface loginFromData {
  username: string;
  password: string;
  id: string;
  mobileNumber: string;
  errors: Array<string>;
  success: string;
}
export interface IStudents {
  _id: string;
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  emailVerified: false;
  mobileNumber: string;
  role: RolesEnum;
  address: string;
  zip: string;
  city: string;
  state: string;
}

export interface AuthState {
  loginFrom: loginFromData;
  userData: {
    id: string;
    firstName: string;
    lastName: string;
    profilePic: string;
    email: string;
    class: string;
    usertype: RolesEnum;
    section: string;
  };
  loading: boolean;
  token: string;
  isLogin: boolean;
  studentList: IStudents[];
  buttonLoading: boolean;
}
export enum RolesEnum {
  ADMIN = 1,
  TEACHER = 2,
  STUDENT = 3,
  ANONYMOUS = 4
}

export type InitialAuthState = AuthState;
