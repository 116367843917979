import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../newTypes/RootState';

import { initialState } from './slice';

const selectDomain = (state: RootState) => {
  if (state) {
    return state.UserGroupState;
  } else {
    return initialState;
  }
};
export const selectUserGroupList = createSelector([selectDomain], (state) => state.UserGroupList);
export const selectUserList = createSelector([selectDomain], (state) => state.UserList);
export const selectSeletedUserList = createSelector([selectDomain], (state) => state.selectedUser);
export const SelectLoading = createSelector([selectDomain], (state) => state.loading);
export const SelectPageNo = createSelector([selectDomain], (state) => state.pageNo);
export const SelectPageSize = createSelector([selectDomain], (state) => state.pageSize);
export const SelectTotalRow = createSelector([selectDomain], (state) => state.totalRow);
export const SelectSearch = createSelector([selectDomain], (state) => state.search);
export const SelectGroupFrom = createSelector([selectDomain], (state) => state.UserGroupForm);
export const selectActiveUserGroupList = createSelector([selectDomain], (state) => state.activeUserGroupList);
