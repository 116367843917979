import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../newTypes/RootState';
import { initialState } from './slice';

const selectDomain = (state: RootState) => {
  if (state && state.productCartState) {
    return state.productCartState;
  } else {
    return initialState;
  }
};

export const SelectLoading = createSelector([selectDomain], (state) => state.loading);
export const SelectPageNo = createSelector([selectDomain], (state) => state.pageNo);
export const SelectPageSize = createSelector([selectDomain], (state) => state.pageSize);
export const SelectTotalRow = createSelector([selectDomain], (state) => state.totalRow);
export const SelectSearch = createSelector([selectDomain], (state) => state.search);

export const SelectStudentAddForm = createSelector([selectDomain], (state) => state.studentAddForm);
export const selectStudentAddList = createSelector([selectDomain], (state) => state.studentAddList);
//Add to Cart //
export const SelectAddToCartForm = createSelector([selectDomain], (state) => state.addToCartForm);
export const selectAddToCartList = createSelector([selectDomain], (state) => state.addToCartList);
export const selectSelectedProductList = createSelector([selectDomain], (state) => state.selectedProductList);
export const selectShowThankyouPage = createSelector([selectDomain], (state) => state.showThankyouPage);
export const SelectConvenienceProductList = createSelector([selectDomain], (state) => state.productList);
