// import { createSelector } from "@reduxjs/toolkit";
import { createSelector } from '@reduxjs/toolkit';

import { initialState } from './slice';
import { RootState } from 'newTypes/RootState';

const selectDomain = (state: RootState) => {
  console.log('state.MarkupState', state);

  if (state && state.CategoryNewState) {
    return state.CategoryNewState;
  } else {
    return initialState;
  }
};

export const selectCategoryFormData = createSelector([selectDomain], (state) => state.CategoryFormData);
export const SelectCategoryList = createSelector([selectDomain], (state) => state.CategoryList);
export const SelectLoading = createSelector([selectDomain], (state) => state.loading);
export const SelectPageNo = createSelector([selectDomain], (state) => state.pageNo);
export const SelectPageSize = createSelector([selectDomain], (state) => state.pageSize);
export const SelectTotalRow = createSelector([selectDomain], (state) => state.totalRow);
export const SelectSearch = createSelector([selectDomain], (state) => state.search);
export const SelectDisableCheck = createSelector([selectDomain], (state) => state.dissableCheck);
