// third-party
import { FormattedMessage } from 'react-intl';

// assets
import {
  CalendarOutlined,
  DashboardOutlined,
  GoldOutlined,
  HomeOutlined,
  UserOutlined,
  QuestionCircleOutlined,
  AudioOutlined,
  TagOutlined,
  DisconnectOutlined,
  BellOutlined,
  MediumOutlined,
  ShoppingCartOutlined,
  AccountBookOutlined
} from '@ant-design/icons';

// type
import { NavItemType } from '../types/menu';

// icons
const icons = {
  DashboardOutlined,
  GoldOutlined,
  HomeOutlined,
  UserOutlined,
  CalendarOutlined,
  QuestionCircleOutlined,
  AudioOutlined,
  TagOutlined,
  DisconnectOutlined,
  BellOutlined,
  MediumOutlined,
  ShoppingCartOutlined,
  AccountBookOutlined
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const custom: NavItemType = {
  id: 'group-dashboard',
  type: 'group',
  children: [
    // {
    //   id: 'filter',
    //   title: <FormattedMessage id="Student" />,
    //   type: 'collapse',
    //   icon: icons.UserOutlined,
    //   children: [
    //     {
    //       id: 'filter',
    //       title: <FormattedMessage id="Student List" />,
    //       type: 'item',
    //       url: '/filter/filterlist'
    //     }
    //   ]
    // },
    {
      id: 'procuct-cart',
      title: <FormattedMessage id="procuct-cart" />,
      type: 'collapse',
      icon: icons.ShoppingCartOutlined,
      children: [
        {
          id: 'products',
          title: <FormattedMessage id="products" />,
          type: 'item',
          url: '/procuct-cart/products'
        },
        // {
        //   id: 'product-details',
        //   title: <FormattedMessage id="product-details" />,
        //   type: 'item',
        //   url: '/procuct-cart/product-details/1',
        //   breadcrumbs: false
        // },
        // {
        //   id: 'product-list',
        //   title: <FormattedMessage id="product-list" />,
        //   type: 'item',
        //   url: '/procuct-cart/product-list',
        //   breadcrumbs: false
        // },
        // {
        //   id: 'add-new-product',
        //   title: <FormattedMessage id="add-new-product" />,
        //   type: 'item',
        //   url: '/procuct-cart/add-new-product'
        // },
        {
          id: 'checkout',
          title: <FormattedMessage id="checkout" />,
          type: 'item',
          url: '/procuct-cart/checkout'
        }
      ]
    },
    {
      id: 'order',
      title: <FormattedMessage id="My Order" />,
      type: 'collapse',
      icon: icons.AccountBookOutlined,
      children: [
        {
          id: 'order-list',
          title: <FormattedMessage id="Order List" />,
          type: 'item',
          url: '/order/orderlist'
        }
      ]
    },
    // {
    //   id: 'report',
    //   title: <FormattedMessage id="Report" />,
    //   type: 'collapse',
    //   icon: icons.UserOutlined,
    //   children: [
    //     {
    //       id: 'report',
    //       title: <FormattedMessage id="Student Report" />,
    //       type: 'item',
    //       url: '/report/studentlist'
    //     }
    //   ]
    // },
    // {
    //   id: 'e-commerce',
    //   title: <FormattedMessage id="e-commerce" />,
    //   type: 'collapse',
    //   icon: icons.ShoppingCartOutlined,
    //   children: [
    //     {
    //       id: 'products',
    //       title: <FormattedMessage id="products" />,
    //       type: 'item',
    //       url: '/apps/e-commerce/products'
    //     },
    //     {
    //       id: 'product-details',
    //       title: <FormattedMessage id="product-details" />,
    //       type: 'item',
    //       url: '/apps/e-commerce/product-details/1',
    //       breadcrumbs: false
    //     },
    //     {
    //       id: 'product-list',
    //       title: <FormattedMessage id="product-list" />,
    //       type: 'item',
    //       url: '/apps/e-commerce/product-list',
    //       breadcrumbs: false
    //     },
    //     {
    //       id: 'add-new-product',
    //       title: <FormattedMessage id="add-new-product" />,
    //       type: 'item',
    //       url: '/apps/e-commerce/add-new-product'
    //     },
    //     {
    //       id: 'checkout',
    //       title: <FormattedMessage id="checkout" />,
    //       type: 'item',
    //       url: '/apps/e-commerce/checkout'
    //     }
    //   ]
    // }
  ]
};

export default custom;
