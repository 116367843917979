// import { createSelector } from "@reduxjs/toolkit";
import { createSelector } from '@reduxjs/toolkit';

import { initialState } from './slice';
import { RootState } from 'newTypes/RootState';

const selectDomain = (state: RootState) => {
  console.log('state.ProductState', state);

  if (state && state.ProductState) {
    return state.ProductState;
  } else {
    return initialState;
  }
};

export const selectproductFormData = createSelector([selectDomain], (state) => state.productFormData);
export const SelectConvenienceProductList = createSelector([selectDomain], (state) => state.productList);
export const SelectLoading = createSelector([selectDomain], (state) => state.loading);
export const SelectPageNo = createSelector([selectDomain], (state) => state.pageNo);
export const SelectPageSize = createSelector([selectDomain], (state) => state.pageSize);
export const SelectTotalRow = createSelector([selectDomain], (state) => state.totalRow);
export const SelectSearch = createSelector([selectDomain], (state) => state.search);
export const SelectDisableCheck = createSelector([selectDomain], (state) => state.dissableCheck);
