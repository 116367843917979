import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '../../../utils copy/@reduxjs/toolkit';
import { StudentAddInterface, ProductCartState, SelectedCartInterface, ProductInterface } from '../types';
import set from 'lodash/set';
export const initialState: ProductCartState = {
  loading: false,
  pageSize: 10,
  totalRow: 0,
  search: '',
  pageNo: 1,
  editIndex: 0,
  isadd: false,
  addToCartForm: {
    productId: '',
    quantity: ''
  },
  addToCartList: [],
  selectedProductList: [],

  studentAddList: [],
  studentAddForm: {
    id: '',
    firstName: '',
    lastName: '',
    email: '',
    mobileNumber: '',
    address: '',
    zip: '',
    city: '',
    state: '',
    coins: 0,
    password: '',
    section: '',
    class: '',
    coinsEarned: 0,
    coinsUsed: 0,
    teacherFirstName: '',
    teacherLastName: ''
  },
  productList: [],
  showThankyouPage: false
};

export const useClinicSlice = createSlice({
  name: 'productCartState',
  initialState,
  reducers: {
    doUpdateFilterStatus: (state, action: PayloadAction<string>) => {},
    setLoading: (state: { loading: boolean }, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setProductList: (state, action: PayloadAction<Array<ProductInterface>>) => {
      state.productList = action.payload;
    },
    doGetProductList: (state) => {},
    setPageSize: (state: { pageSize: number }, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },
    setPageNo: (state: { pageNo: number }, action: PayloadAction<number>) => {
      state.pageNo = action.payload;
    },
    setTotalRow: (state: { totalRow: number }, action: PayloadAction<number>) => {
      state.totalRow = action.payload;
    },
    setSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
    },
    setShowThankyouPage: (state, action: PayloadAction<boolean>) => {
      state.showThankyouPage = action.payload;
    },

    updateFormValue: (state: any, action: PayloadAction<{ key: string; value: any }>) => {
      console.log('action.payload.key,action.payload.value', action.payload.key, action.payload.value);
      set(state, `Form.${action.payload.key}`, action.payload.value);
    },
    doGetList: (state: any) => {},
    doGetActiveList: (state: any) => {},
    doAdd: (state: any, action: PayloadAction<{ callback: () => void }>) => {},
    doStudentUpdate: (state: any, action: PayloadAction<{ callback: () => void }>) => {},
    doStudentEdit: (state: any, action: PayloadAction<string>) => {},
    setDataInForm: (state, action: PayloadAction<StudentAddInterface>) => {
      state.studentAddForm = action.payload;
    },
    setList: (state, action: PayloadAction<Array<StudentAddInterface>>) => {
      state.studentAddList = action.payload;
    },
    removeCartProduct: (state, action: PayloadAction<any>) => {},
    doStudentDelete: (state: any, action: PayloadAction<{ id: string; callback: () => void }>) => {},
    // addItemList: (state, action: PayloadAction<FilterItem>) => {
    //   state.Form.items.push(action.payload);
    //   state.Form.itemTitle = '';
    // },

    updateStudentFormValue: (state, action: PayloadAction<{ key: string; value: any }>) => {
      set(state, `studentAddForm.${action.payload.key}`, action.payload.value);
    },

    //****Add to Cart Section****//
    clearAddToCardFrom: (state) => {
      state.addToCartForm.productId = '';
      state.addToCartForm.quantity = '';
    },
    doAddToCartProduct: (state, action: PayloadAction<{ id: string; callback: () => void }>) => {},
    doUpdateCartQuentity: (state, action: PayloadAction<{ id: string; quentity: any; callback: () => void }>) => {},
    doGetAddToCartList: (state) => {},
    setSelectedProductList: (state, action: PayloadAction<Array<SelectedCartInterface>>) => {
      state.selectedProductList = action.payload;
    },
    doProductBooking: (state, action: PayloadAction<{ callback: () => void }>) => {},
    clearSearch: (state) => {
      state.search = '';
    }
  }
});

export const { reducer, actions, name: sliceKey } = useClinicSlice;
