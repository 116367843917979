import { lazy } from 'react';

// project import
import MainLayout from '../layout/MainLayout';
import CommonLayout from '../layout/CommonLayout';
import Loadable from '../components/Loadable';
import AuthGuard from '../utils/route-guard/AuthGuard';
// render - dashboard
const DashboardDefault = Loadable(lazy(() => import('../pages/dashboard/default')));
const DashboardAnalytics = Loadable(lazy(() => import('../pages/dashboard/analytics')));
const User = Loadable(lazy(() => import('../pages/UserMain/index')));
const Story = Loadable(lazy(() => import('../pages/Catalouge/StoryList')));
const StoryForm = Loadable(lazy(() => import('../pages/Catalouge/StoryForm')));
const StoryPrompt = Loadable(lazy(() => import('../pages/Catalouge/StoryPrompt')));
const StoryPromptForm = Loadable(lazy(() => import('../pages/Catalouge/StoryPromptForm')));
const CommunityStory = Loadable(lazy(() => import('../pages/Catalouge/CommunityStory/CommunityStoryList')));
const CommunityStoryForm = Loadable(lazy(() => import('../pages/Catalouge/CommunityStory/CommunityStoryForm')));
const DaynamicStory = Loadable(lazy(() => import('../pages/Catalouge/DynamicStory/DynamicStoryForm')));
const DaynamicStoryList = Loadable(lazy(() => import('../pages/Catalouge/DynamicStory/DynamicStoryList')));
// const Revenue = Loadable(lazy(() => import('../pages/Revenue/index')));
const Settings = Loadable(lazy(() => import('../pages/Controls/Settings')));
const AppContactUS = Loadable(lazy(() => import('../pages/contact-us')));
const UserGroupList = Loadable(lazy(() => import('../pages/user-group/usergrouplist')));
const UserGroupForm = Loadable(lazy(() => import('../pages/user-group/usergroupform')));
const DiscountList = Loadable(lazy(() => import('../pages/Discount/list')));
const DiscountForm = Loadable(lazy(() => import('../pages/Discount/form')));
const SubscriptionList = Loadable(lazy(() => import('../pages/Subscription/list')));
const SubscriptionForm = Loadable(lazy(() => import('../pages/Subscription/form')));
const SubscriptionReport = Loadable(lazy(() => import('../pages/SubscriptionReport')));
const DiscountReport = Loadable(lazy(() => import('../pages/DiscountReport')));
const StoryReport = Loadable(lazy(() => import('../pages/StoryReport')));
const Filter = Loadable(lazy(() => import('../pages/Filter/index')));
const FilterList = Loadable(lazy(() => import('../pages/Filter/FilterList')));
const StudntDetails = Loadable(lazy(() => import('../pages/Filter/StudentDetails')));
const UserForm = Loadable(lazy(() => import('../pages/Filter/Form')));
const CategoryForm = Loadable(lazy(() => import('../pages/Category/Form')));
const CategoryTeacherReport = Loadable(lazy(() => import('../pages/Category/TeacherReport')));
const CategoryTeacherDetailReport = Loadable(lazy(() => import('../pages/Category/TeacherDetailPage')));

const CategoryStudentReport = Loadable(lazy(() => import('../pages/Category/StudentTeacherDetailPage')));
const StudentCoinDetail = Loadable(lazy(() => import('../pages/Category/StudentCoinDetail')));
const CategoryList = Loadable(lazy(() => import('../pages/Category/List')));
//add to cart user//
const AppECommProducts = Loadable(lazy(() => import('pages/userproduct/product')));
const AppECommProductDetails = Loadable(lazy(() => import('pages/userproduct/product-details')));
const AppECommProductList = Loadable(lazy(() => import('pages/userproduct/products-list')));
const AppECommCheckout = Loadable(lazy(() => import('pages/userproduct/checkout')));
const AppECommAddProduct = Loadable(lazy(() => import('pages/userproduct/add-product')));
//OrderList//
const OrderList = Loadable(lazy(() => import('pages/orderList/OrderList')));
// render - sample page
const ProductForm = Loadable(lazy(() => import('../pages/Product/ProductForm')));
const ProductList = Loadable(lazy(() => import('../pages/Product/Productlist')));
// ==============================|| MAIN ROUTING ||============================== //

const CategoryNewForm = Loadable(lazy(() => import('../pages/CategoryNew/CategoryNewForm')));
const CategoryNewList = Loadable(lazy(() => import('../pages/CategoryNew/CategoryNewList')));
const ReportTeacherList = Loadable(lazy(() => import('../pages/Report/TeacherReport')));
const ReportStudentNewList = Loadable(lazy(() => import('../pages/Report/StudentReport')));

const UserProfile = Loadable(lazy(() => import('../pages/apps/profiles/user')));
const UserTabPersonal = Loadable(lazy(() => import('sections/apps/profiles/user/TabPersonal')));
const UserTabPayment = Loadable(lazy(() => import('sections/apps/profiles/user/TabPayment')));
const UserTabPassword = Loadable(lazy(() => import('sections/apps/profiles/user/TabPassword')));
const UserTabSettings = Loadable(lazy(() => import('sections/apps/profiles/user/TabSettings')));

const AccountProfile = Loadable(lazy(() => import('pages/apps/profiles/account')));
const AccountTabProfile = Loadable(lazy(() => import('sections/apps/profiles/account/TabProfile')));
const AccountTabPersonal = Loadable(lazy(() => import('sections/apps/profiles/account/TabPersonal')));
const AccountTabAccount = Loadable(lazy(() => import('sections/apps/profiles/account/TabAccount')));
const AccountTabPassword = Loadable(lazy(() => import('sections/apps/profiles/account/TabPassword')));
const AccountTabRole = Loadable(lazy(() => import('sections/apps/profiles/account/TabRole')));
const AccountTabSettings = Loadable(lazy(() => import('sections/apps/profiles/account/TabSettings')));

const MainRoutes = {
  path: '/',
  children: [
    {
      path: '/',
      element: (
        <AuthGuard>
          <MainLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: 'dashboard',
          children: [
            {
              path: 'default',
              element: <DashboardDefault />
            },
            {
              path: 'analytics',
              element: <DashboardAnalytics />
            }
          ]
        },

        {
          path: 'user',
          children: [
            {
              path: 'user',
              element: <User />
            }
          ]
        },
        {
          path: 'filter',
          children: [
            {
              path: 'filter',
              element: <Filter />
            },
            {
              path: 'filter/:id',
              element: <Filter />
            },
            {
              path: 'studntdetails',
              element: <StudntDetails />
            },
            {
              path: 'studntdetails/:id',
              element: <StudntDetails />
            },
            {
              path: 'filterlist',
              element: <FilterList />
            },
            {
              path: 'adduser',
              element: <UserForm />
            },
            {
              path: 'adduser/:id',
              element: <UserForm />
            }
          ]
        },
        {
          path: 'order',
          children: [
            {
              path: 'orderlist',
              element: <OrderList />
            }
          ]
        },
        {
          path: 'category',
          children: [
            {
              path: 'form/',
              element: <CategoryForm />
            },
            {
              path: 'form/:id',
              element: <CategoryForm />
            },
            {
              path: 'teacher-report',
              element: <CategoryTeacherReport />
            },
            {
              path: 'teacher-detail',
              element: <CategoryTeacherDetailReport />
            },
            {
              path: 'teacher-detail/:id',
              element: <CategoryTeacherDetailReport />
            },
            {
              path: 'student-detail',
              element: <CategoryStudentReport />
            },
            {
              path: 'student-detail/:id',
              element: <CategoryStudentReport />
            },
            {
              path: 'student-coin-detail',
              element: <StudentCoinDetail />
            },
            {
              path: 'student-coin-detail/:id',
              element: <StudentCoinDetail />
            },
            
            {
              path: 'list',
              element: <CategoryList />
            }
          ]
        },
        {
          path: 'report',
          children: [
            {
              path: 'teacherlist',
              element: <ReportTeacherList />
            },
            {
              path: 'studentlist',
              element: <ReportStudentNewList />
            }
          ]
        },
        {
          path: 'categorynew',
          children: [
            {
              path: 'form/',
              element: <CategoryNewForm />
            },
            {
              path: 'form/:id',
              element: <CategoryNewForm />
            },
            {
              path: 'list',
              element: <CategoryNewList />
            }
          ]
        },
        {
          path: 'product',
          children: [
            {
              path: 'form/',
              element: <ProductForm />
            },
            {
              path: 'form/:id',
              element: <ProductForm />
            },
            {
              path: 'list',
              element: <ProductList />
            }
          ]
        },
        {
          path: 'Catalouge',
          children: [
            {
              path: 'story',
              element: <Story />
            },
            {
              path: 'storyform',
              element: <StoryForm />
            },
            {
              path: 'storyform/:id',
              element: <StoryForm />
            },
            {
              path: 'community/story',
              element: <CommunityStory />
            },
            {
              path: 'community/storyform',
              element: <CommunityStoryForm />
            },
            {
              path: 'storyprompt',
              element: <StoryPrompt />
            },
            {
              path: 'storypromptform/:id',
              element: <StoryPromptForm />
            },
            {
              path: 'storypromptform',
              element: <StoryPromptForm />
            },
            {
              path: 'daynamicstory/:id',
              element: <DaynamicStory />
            },
            {
              path: 'daynamicstory',
              element: <DaynamicStory />
            },
            {
              path: 'daynamicstorylist',
              element: <DaynamicStoryList />
            }
          ]
        },
        {
          path: 'reports',

          children: [
            {
              path: 'subscription',
              element: <SubscriptionReport />
            },
            {
              path: 'discount',
              element: <DiscountReport />
            },
            {
              path: 'story',
              element: <StoryReport />
            }
          ]
        },
        {
          path: 'usergroup',
          children: [
            {
              path: 'usergrouplist',
              element: <UserGroupList />
            },
            {
              path: 'usergroupform/:id',
              element: <UserGroupForm />
            },
            {
              path: 'usergroupform',
              element: <UserGroupForm />
            }
          ]
        },
        {
          path: 'discount',
          children: [
            {
              path: 'list',
              element: <DiscountList />
            },
            {
              path: 'form/:id',
              element: <DiscountForm />
            },
            {
              path: 'form',
              element: <DiscountForm />
            }
          ]
        },
        {
          path: 'subscription',
          children: [
            {
              path: 'list',
              element: <SubscriptionList />
            },
            {
              path: 'form/:id',
              element: <SubscriptionForm />
            },
            {
              path: 'form',
              element: <SubscriptionForm />
            }
          ]
        },
        {
          path: 'controls',
          children: [
            {
              path: 'settings',
              element: <Settings />
            }
          ]
        },
        {
          path: 'procuct-cart',
          children: [
            {
              path: 'products',
              element: <AppECommProducts />
            },
            {
              path: 'product-details/:id',
              element: <AppECommProductDetails />
            },
            {
              path: 'product-list',
              element: <AppECommProductList />
            },
            {
              path: 'add-new-product',
              element: <AppECommAddProduct />
            },
            {
              path: 'checkout',
              element: <AppECommCheckout />
            }
          ]
        },
        {
          path: 'apps',
          children: [
         
            {
              path: 'profiles',
              children: [
                {
                  path: 'account',
                  element: <AccountProfile />,
                  children: [
                    {
                      path: 'basic',
                      element: <AccountTabProfile />
                    },
                    {
                      path: 'personal',
                      element: <AccountTabPersonal />
                    },
                    {
                      path: 'my-account',
                      element: <AccountTabAccount />
                    },
                    {
                      path: 'password',
                      element: <AccountTabPassword />
                    },
                    {
                      path: 'role',
                      element: <AccountTabRole />
                    },
                    {
                      path: 'settings',
                      element: <AccountTabSettings />
                    },
                
                  ]
                },
                {
                  path: 'user',
                  element: <UserProfile />,
                  children: [
                    {
                      path: 'personal',
                      element: <UserTabPersonal />
                    },
                    {
                      path: 'payment',
                      element: <UserTabPayment />
                    },
                    {
                      path: 'password',
                      element: <UserTabPassword />
                    },
                    {
                      path: 'settings',
                      element: <UserTabSettings />
                    }
                  ]
                },
              ]
            },
            {
              path: 'e-commerce',
              children: [
                {
                  path: 'products',
                  element: <AppECommProducts />
                },
                {
                  path: 'product-details/:id',
                  element: <AppECommProductDetails />
                },
                {
                  path: 'product-list',
                  element: <AppECommProductList />
                },
                {
                  path: 'add-new-product',
                  element: <AppECommAddProduct />
                },
                {
                  path: 'checkout',
                  element: <AppECommCheckout />
                }
              ]
            }
          ]
        },
      ]
    },
    {
      path: '/',
      element: <CommonLayout layout="simple" />,
      children: [
        {
          path: 'contact-us',
          element: <AppContactUS />
        }
      ]
    }
  ]
};

export default MainRoutes;
