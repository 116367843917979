import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../newTypes/RootState';

import { initialState } from './slice';

const selectDomain = (state: RootState) => {
  if (state) {
    return state.storyState;
  } else {
    return initialState;
  }
};

export const selectStoryForm = createSelector([selectDomain], (state) => state.StoryFormData);
export const selectStoryList = createSelector([selectDomain], (state) => state.StoryList);
// selectStoryBranchForm
export const selectStoryBranchForm = createSelector([selectDomain], (state) => state.StoryBranchFormData);

//community selectors

export const selectCommunityStoryForm = createSelector([selectDomain], (state) => state.CommunityStoryFormData);
export const selectCommunityStoryList = createSelector([selectDomain], (state) => state.CommunityStoryList);
// selectStoryBranchForm

export const selectSettingForm = createSelector([selectDomain], (state) => state.settingForm);

export const SelectLoading = createSelector([selectDomain], (state) => state.loading);
export const SelectPageNo = createSelector([selectDomain], (state) => state.pageNo);
export const SelectPageSize = createSelector([selectDomain], (state) => state.pageSize);
export const SelectTotalRow = createSelector([selectDomain], (state) => state.totalRow);
export const SelectSearch = createSelector([selectDomain], (state) => state.search);

//Prompt
export const selectPromptForm = createSelector([selectDomain], (state) => state.StoryPromptFormData);
export const selectPromptList = createSelector([selectDomain], (state) => state.StorPromptyList);
export const selectSelectedStroy = createSelector([selectDomain], (state) => state.selectedStory);

export const selectCommunityEditForm = createSelector([selectDomain], (state) => state.communityStoryEdit);
export const selectStoryEditDataModel = createSelector([selectDomain], (state) => state.storyEditDataModel);
export const selectStoryEditPartModel = createSelector([selectDomain], (state) => state.storyEditPartModel);
export const selectStoryEndModal = createSelector([selectDomain], (state) => state.storyEndModal);
export const selectAddPartModal = createSelector([selectDomain], (state) => state.storyAddPartModel);
export const selectStoryEditFrom = createSelector([selectDomain], (state) => state.storyEditForm);
export const selectStoryTree = createSelector([selectDomain], (state) => state.treedata);

//Dynamic
export const selectDynamicForm = createSelector([selectDomain], (state) => state.StoryDynamicForm);
export const selectDynamictList = createSelector([selectDomain], (state) => state.StoryDynamicList);
export const selectActiveStory = createSelector([selectDomain], (state) => state.ActiveStory);
export const selectActiveCommunityStory = createSelector([selectDomain], (state) => state.ActiveCommunityStory);
