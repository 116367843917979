import { call, delay, put, select, takeLatest } from 'redux-saga/effects';
import { actions } from './slice';
import { openSnackbar } from 'store/reducers/snackbar';
import { AxiosResponse } from 'axios';
import { SelectOrderDate, SelectPageNo, SelectPageSize, SelectSearch } from './selector';
import { doGetOrderList } from 'utils copy/request';

import CatchBlockFunction from 'hooks/CatchBlockFunction';
import { FilterInterface } from 'pages/Report/types';
import { selectReportPageFilter } from 'pages/Report/redux/selector';
import { StudentType } from 'pages/Filter/types';
import { SelectStudenttype } from 'pages/Filter/redux/selector';

export function* doGetAllStudentOrderListRequest() {
  yield delay(500);
  try {
    
    const pageNo: number = yield select(SelectPageNo);
    const pageSize: number = yield select(SelectPageSize);
    const searchText: string = yield select(SelectSearch);
    const OrderDate: string = yield select(SelectOrderDate);
    const studentType: StudentType = yield select(SelectStudenttype);
    const filter: FilterInterface = yield select(selectReportPageFilter);
    const response: AxiosResponse = yield call(
      doGetOrderList,
      `?pageNo=${pageNo}&pageSize=${pageSize}&studentType=${studentType}&classFilter=${filter.selectedClassFilter}${searchText.length > 0 ? '&search=' + searchText : ''}&OrderDate=${OrderDate}`
    );
    yield put(actions.setLoading(false));
    if (response.data.status == false) {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true
        })
      );
      return;
    }
    yield put(actions.setTotalRow(response.data.data[0].meta ? response.data.data[0].meta.total : 0));
    yield put(actions.setList(response.data.data[0].results));
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}

export function* OrderRepoSaga() {
  yield takeLatest(actions.doGetList, doGetAllStudentOrderListRequest);
}
