import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '../../../utils copy/@reduxjs/toolkit';
import { UserInterface, userState } from '../types';
import { SubscriptionInterface } from 'pages/Subscription/types';
export const initialState: userState = {
  loading: false,
  pageSize: 10,
  totalRow: 0,
  search: '',
  pageNo: 1,
  UserList: [],
  UserFormData: {
    created_at: '',
    email: '',
    gender: '',
    id: '',
    profile_pic_url: '',
    is_active: 0,
    name: '',
    userstatus: true,
    isSuscribed:0
  },
  editIndex: 0,
  isadd: false,
  showModal:false,
  selectedPlan:"",
  planList:[],
  selectedUserId:""
};

export const useClinicSlice = createSlice({
  name: 'userSate',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },
    setPageNo: (state, action: PayloadAction<number>) => {
      state.pageNo = action.payload;
    },
    setTotalRow: (state, action: PayloadAction<number>) => {
      state.totalRow = action.payload;
    },
    setSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
    },
    setUserList: (state, action: PayloadAction<Array<UserInterface>>) => {
      state.UserList = action.payload;
    },
    setPlanList: (state, action: PayloadAction<Array<SubscriptionInterface>>) => {
      state.planList = action.payload;
    },
    updateNewUserFormValue: (state, action: PayloadAction<{ key: string; value: any }>) => {
      console.log('action.payload.key,action.payload.value', action.payload.key, action.payload.value);
      //@ts-ignore
      state.UserFormData[action.payload.key] = action.payload.value;
    },
    NewUserAdded: (state, action: PayloadAction<{ callback: () => void }>) => {
      console.log('Yo Reached at UserAdded');
      state.UserList.push(state.UserFormData);
    },
    clearNewUserFrom: (state) => {},
    doGetList: (state) => {},
    doGetPlanList: (state) => {},
    doAssignPlanToUser: (state) => {},
    DeleteUser: (state, action: PayloadAction<number>) => {
      state.UserList.splice(action.payload, 1);
    },
    getDataForEdit: (state, action: PayloadAction<number>) => {
      state.editIndex = action.payload;
      state.UserFormData = state.UserList[action.payload];
    },
    setSelectedPlan : (state, action: PayloadAction<string>) => {
      state.selectedPlan = action.payload;      
    },
    setSelectedUserId : (state, action: PayloadAction<string>) => {
      state.selectedUserId = action.payload;      
    },
    clearPlanForm: (state) => {
      state.selectedPlan = "";   
      state.selectedUserId=""
    },
    toggalModal: (state, ) => {
      state.showModal = !state.showModal;      
    }
  }
});

export const { reducer, actions, name: sliceKey } = useClinicSlice;
