import { call, delay, put,  select,  takeLatest } from 'redux-saga/effects';
import { actions } from './slice';
import { AxiosResponse } from 'axios';
// import {  SelectPageNo,  SelectSearch } from './selector';

import { GetDashboardlistRequest } from 'utils copy/request';
import CatchBlockFunction from 'hooks/CatchBlockFunction';
import { SelectdateFilter } from './selector';

// import { openSnackbar } from 'store/reducers/snackbar';
export function* getDashboardListRequest() {

  yield delay(500);
  const dateFIlter: number = yield select(SelectdateFilter);

  try {
    const response: AxiosResponse = yield call(
      GetDashboardlistRequest,`?dateFilter=${dateFIlter}`
    );
    yield put(actions.setLoading(false));
    if (response.data.status == false) {
      return;
    }
    
    yield put(actions.setDashboardsList(response.data));
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}

export function* authRepoSagaDashboard() {
  yield takeLatest(actions.doGetDashboardList, getDashboardListRequest);
 
}

