import { PayloadAction } from '@reduxjs/toolkit';
import { LoginMessage } from 'Messages/LoginMessage';
import { createSlice } from '../../../utils copy/@reduxjs/toolkit';
import { AuthState, IStudents, RolesEnum } from '../types';

export const initialState: AuthState = {
  loading: false,
  loginFrom: {
    username: '',
    password: '',
    errors: [],
    success: '',
    mobileNumber: '',
    id: ''
  },
  userData: {
    id: '',
    firstName: '',
    lastName: '',
    profilePic: '',
    email: '',
    usertype: RolesEnum.ADMIN,
    class: '',
    section: ''
  },
  token: localStorage.getItem('token') || '',
  isLogin: true,
  studentList: [],
  buttonLoading: false
};

export const useClinicSlice = createSlice({
  name: 'AuthState',
  initialState,
  reducers: {
    setUpdateTocken: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
      localStorage.setItem('token', action.payload);
    },

    setisLogin: (state, action: PayloadAction<boolean>) => {
      state.isLogin = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setButtonLoading: (state, action: PayloadAction<boolean>) => {
      state.buttonLoading = action.payload;
    },
    setProfileData: (
      state,
      action: PayloadAction<{
        id: string;
        firstName: string;
        lastName: string;
        email: string;
        usertype: RolesEnum;
        class: string;
        section: string;
      }>
    ) => {
      state.userData.id = action.payload.id;
      state.userData.firstName = action.payload.firstName;
      state.userData.lastName = action.payload.lastName;
      state.userData.email = action.payload.email;
      state.userData.usertype = action.payload.usertype;
      state.userData.class = action.payload.class;
      state.userData.section = action.payload.section;
    },
    getUserProfile: (state) => {},

    //Working
    doLogin: (state, action: PayloadAction<{ callback: () => void }>) => {
      state.loginFrom.errors = [];
    },
    //Working
    doLogOut: (state) => {
      state.isLogin = true;
    },
    addLoginError: (state, action: PayloadAction<string>) => {
      state.loginFrom.errors.push(action.payload);
    },
    //Working
    loginSuccess: (state) => {
      state.loginFrom.success = LoginMessage.LOGIN_SUCCESS_MESSAGE;
    },
    updateFormValue: (state, action: PayloadAction<{ key: string; value: any }>) => {
      //@ts-ignore
      state.loginFrom[action.payload.key] = action.payload.value;
      state.loginFrom.errors = [];
    },
    setStudents: (state, action: PayloadAction<Array<IStudents>>) => {
      state.studentList = action.payload;
    },
    doGetStudents: (state, action: PayloadAction<{ callback: () => void }>) => {},
    clearForm: (state) => {
      state.loginFrom.errors = [];
      state.loginFrom.mobileNumber = '';
      state.loginFrom.password = '';
      state.loginFrom.username = '';
      state.loginFrom.success = '';
      state.loginFrom.id = '';
    }
  }
});

export const { reducer, actions, name: sliceKey } = useClinicSlice;
