import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../newTypes/RootState';
import { initialState } from './slice';

const selectDomain = (state: RootState) => {
  console.log('state.user', state);

  if (state) {
    if (state.userSate) {
      return state.userSate;
    } else {
      return initialState;
    }
  } else {
    return initialState;
  }
};

export const selectUserForm = createSelector([selectDomain], (state) => state.UserFormData);
export const selectUserList = createSelector([selectDomain], (state) => state.UserList);
export const SelectLoading = createSelector(
  [selectDomain],
  (state) => state.loading
);
export const SelectPageNo = createSelector(
  [selectDomain],
  (state) => state.pageNo
);
export const SelectPageSize = createSelector(
  [selectDomain],
  (state) => state.pageSize
);
export const SelectTotalRow = createSelector(
  [selectDomain],
  (state) => state.totalRow
);
export const SelectSearch = createSelector(
  [selectDomain],
  (state) => state.search
);
export const selectShowModal = createSelector(
  [selectDomain],
  (state) => state.showModal
);
export const selectselectedPlan = createSelector(
  [selectDomain],
  (state) => state.selectedPlan
);
export const selectSelectedUserId = createSelector(
  [selectDomain],
  (state) => state.selectedUserId
);
export const selectPlanList = createSelector(
  [selectDomain],
  (state) => state.planList
);



