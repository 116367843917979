// third-party
import { FormattedMessage } from 'react-intl';

// assets
import {
  CalendarOutlined,
  DashboardOutlined,
  GoldOutlined,
  HomeOutlined,
  UserOutlined,
  QuestionCircleOutlined,
  AudioOutlined,
  TagOutlined,
  DisconnectOutlined,
  BellOutlined,
  MediumOutlined,
  AccountBookOutlined
} from '@ant-design/icons';

// type
import { NavItemType } from '../types/menu';
import React from 'react';

// icons
const icons = {
  DashboardOutlined,
  GoldOutlined,
  HomeOutlined,
  UserOutlined,
  CalendarOutlined,
  QuestionCircleOutlined,
  AudioOutlined,
  TagOutlined,
  DisconnectOutlined,
  BellOutlined,
  MediumOutlined,
  AccountBookOutlined
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const custom: NavItemType = {
  id: 'group-dashboard',
  type: 'group',
  children: [
    {
      id: 'teacher',
      title: <FormattedMessage id="Teacher" />,
      type: 'collapse',
      icon: icons.UserOutlined,
      children: [
        {
          id: 'form',
          title: <FormattedMessage id="Teacher List" />,
          type: 'item',
          url: '/category/list'
        },
        {
          id: 'report',
          title: <FormattedMessage id="Teacher Report" />,
          type: 'item',
          url: '/category/teacher-report'
        }
      ]
    },
    {
      id: 'filter',
      title: <FormattedMessage id="Student" />,
      type: 'collapse',
      icon: icons.UserOutlined,
      children: [
        {
          id: 'filter',
          title: <FormattedMessage id="Student List" />,
          type: 'item',
          url: '/filter/filterlist'
        }
      ]
    },
    {
      id: 'product',
      title: <FormattedMessage id="Product" />,
      type: 'collapse',
      icon: icons.UserOutlined,
      children: [
        {
          id: 'product',
          title: <FormattedMessage id="Product List" />,
          type: 'item',
          url: '/product/list'
        }
      ]
    },
    {
      id: 'order',
      title: <FormattedMessage id="Order" />,
      type: 'collapse',
      icon: icons.AccountBookOutlined,
      children: [
        {
          id: 'order-list',
          title: <FormattedMessage id="Order List" />,
          type: 'item',
          url: '/order/orderlist'
        }
      ]
    },
    {
      id: 'category',
      title: <FormattedMessage id="Category" />,
      type: 'collapse',
      icon: icons.UserOutlined,
      children: [
        {
          id: 'category',
          title: <FormattedMessage id="Category List" />,
          type: 'item',
          url: '/categorynew/list'
        }
      ]
    }
    // {
    //   id: 'report',
    //   title: <FormattedMessage id="Report" />,
    //   type: 'collapse',
    //   icon: icons.UserOutlined,
    //   children: [
    //     {
    //       id: 'teacherlist',
    //       title: <FormattedMessage id="Teacher Report" />,
    //       type: 'item',
    //       url: '/report/teacherlist'
    //     },
    //     {
    //       id: 'studentlist',
    //       title: <FormattedMessage id="Student Report" />,
    //       type: 'item',
    //       url: '/report/studentlist'
    //     }
    //   ]
    // },
  ]
};

export default custom;
