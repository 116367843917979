import { call, delay, put, select, takeLatest } from 'redux-saga/effects';
import { actions } from './slice';
import { openSnackbar } from 'store/reducers/snackbar';
import { AxiosResponse } from 'axios';
import { SelectPageNo, SelectPageSize, SelectSearch, SelectTeacherPageNo, SelectTeacherPageSize, SelectTeacherSearch, selectReportPageFilter } from './selector';
import { doGetAllClassFilterList, doGetStudentOrderList, doGetTeacherFilterList, doGetTeacherRequestList } from 'utils copy/request';
import CatchBlockFunction from 'hooks/CatchBlockFunction';
import { FilterInterface } from '../types';
import { SelectOrderDate } from 'pages/orderList/redux/selector';

export function* doGetAllStudentOrderListRequest() {
  yield delay(500);
  try {
    const pageNo: number = yield select(SelectPageNo);
    const pageSize: number = yield select(SelectPageSize);
    const searchText: string = yield select(SelectSearch);
    const OrderDate: string = yield select(SelectOrderDate);
    const filter: FilterInterface = yield select(selectReportPageFilter);
    const response: AxiosResponse = yield call(
      doGetStudentOrderList,
      `?pageNo=${pageNo}&pageSize=${pageSize}${searchText.length > 0 ? '&search=' + searchText : ''}&teacherFilter=${filter.selectedTechaerFilter}&date=${OrderDate}&classFilter=${filter.selectedClassFilter}`
    );
    yield put(actions.setLoading(false));
    if (response.data.status == false) {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true
        })
      );
      return;
    }
    yield put(actions.setTotalRow(response.data.data[0].meta ? response.data.data[0].meta.total : 0));
    yield put(actions.setList(response.data.data[0].results));
    
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}

export function* doGetAllTeacherListRequest() {
  yield delay(500);
  try {
    
    const pageNo: number = yield select(SelectTeacherPageNo);
    const pageSize: number = yield select(SelectTeacherPageSize);
    const searchText: string = yield select(SelectTeacherSearch);
    const filter: FilterInterface = yield select(selectReportPageFilter);
    const response: AxiosResponse = yield call(
      doGetTeacherRequestList,
      `?pageNo=${pageNo}&pageSize=${pageSize}${searchText.length > 0 ? '&search=' + searchText : ''}&teacherFilter=${filter.selectedTechaerFilter}&classFilter=${filter.selectedClassFilter}`
    );
    yield put(actions.setTeacherLoading(false));
    if (response.data.status == false) {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true
        })
      );
      return;
    }
    
    yield put(actions.setTeacherTotalRow(response.data.data[0].meta ? response.data.data[0].meta.total : 0));
    yield put(actions.setTeacherList(response.data.data[0].results));
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* doGetAllFilterTeacherListRequest() {
  yield delay(500);
  try {
    const response: AxiosResponse = yield call(
      doGetTeacherFilterList,
    );
    
    yield put(actions.setLoading(false));
    if (response.data.status == false) {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true
        })
      );
      return;
    }
    yield put(actions.setFilterTeacherList(response.data));
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* doGetAllClassListRequest() {
  yield delay(500);
  try {
    const response: AxiosResponse = yield call(
      doGetAllClassFilterList,
    );
    
    if (response.data.status == false) {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true
        })
      );
      return;
    }
    
    yield put(actions.setfilterclassList(response.data));
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* ReportsStateRepoSaga() {
  yield takeLatest(actions.doGetList, doGetAllStudentOrderListRequest);
  yield takeLatest(actions.doGetTeacherList, doGetAllTeacherListRequest);
  yield takeLatest(actions.doGetAllClassList, doGetAllClassListRequest);
  yield takeLatest(actions.doGetAllFilterTeacherList, doGetAllFilterTeacherListRequest);
}
