import { call, delay, put, select, takeLatest } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';

import { actions } from './slice';

import {
  DeleteProductRequest,
  GetProductNewEditRequest,
  GetProductlist,
  SaveProductRequest,
  UpdatedProductRequest,
  uplodeImageRequest
} from 'utils copy/request';
import { selectproductFormData, SelectPageNo, SelectSearch } from './selector';
import { ProductInterface } from '../types';

import { openSnackbar } from 'store/reducers/snackbar';
import CatchBlockFunction from 'hooks/CatchBlockFunction';

export function* ProductAddRequest(action: { payload: { callback: any } }) {
  yield delay(500);

  const form: ProductInterface = yield select(selectproductFormData);

  if (form.coins == 0) {
    yield put(
      openSnackbar({
        open: true,
        message: 'please fill coins',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      })
    );

    return;
  }
  if (form.photo.length == 0) {
    yield put(
      openSnackbar({
        open: true,
        message: 'please select photo',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      })
    );

    return;
  }
  if (form.title.length == 0) {
    yield put(
      openSnackbar({
        open: true,
        message: 'please fill title',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      })
    );

    return;
  }
  if (Number(form.stockCount) == 0) {
    yield put(
      openSnackbar({
        open: true,
        message: 'please fill Stock Of The Product',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      })
    );

    return;
  }
  if (form.description.length == 0) {
    yield put(
      openSnackbar({
        open: true,
        message: 'please fill description',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      })
    );

    return;
  }
  if (form.shortDescription.length == 0) {
    yield put(
      openSnackbar({
        open: true,
        message: 'please fill short description',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      })
    );

    return;
  }
  try {
    const response: AxiosResponse = yield call(SaveProductRequest, form);
    if (response.data.status == false) {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'success'
          },
          close: false
        })
      );
      return;
    }
    yield put(
      openSnackbar({
        open: true,
        message: response.data.message,
        variant: 'alert',
        alert: {
          color: 'success'
        },
        close: false
      })
    );
    yield call(action?.payload?.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}

export function* getProductListRequest() {
  yield delay(500);

  try {
    const pageNo: number = yield select(SelectPageNo);
    const searchText: string = yield select(SelectSearch);
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(
      GetProductlist,
      `?pageNo=${pageNo}&pageSize=${10000}${searchText.length > 0 ? '&search=' + searchText : ''}`
    );
    yield put(actions.setLoading(false));
    if (response.data.status == false) {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'success'
          },
          close: false
        })
      );
      return;
    }
    yield put(actions.setTotalRow(response.data.data.meta ? response.data.data.meta.total : 0));
    yield put(actions.setProductList(response.data.data[0].results));
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* FileUploadRequest(action: { payload: { data: any; type: number; callback: any } }) {
  yield delay(500);
  try {
    const response: AxiosResponse = yield call(uplodeImageRequest, action.payload.data);
    if (response.data.status == false) {
      openSnackbar({
        open: true,
        message: response.data.message,
        variant: 'alert',
        alert: {
          color: 'success'
        },
        close: false
      });

      return;
    } else {
      openSnackbar({
        open: true,
        message: response.data.message,
        variant: 'alert',
        alert: {
          color: 'success'
        },
        close: false
      });
    }
    yield put(
      actions.updateNewProductFormValue({
        key: 'photo',
        value: response.data.data.name
      })
    );
    yield call(action?.payload?.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* deleteAnnouncement(action: { payload: string }) {
  yield delay(500);

  try {
    const response: AxiosResponse = yield call(DeleteProductRequest, action.payload);
    console.log(response);

    yield put(actions.doGetProductList());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* CategoryUpdatedRequest(action: { payload: { callback: any } }) {
  yield delay(500);

  const form: ProductInterface = yield select(selectproductFormData);
  try {
    const response: AxiosResponse = yield call(UpdatedProductRequest, form);
    if (response.data.status == false) {
      // toast.error(response.data.message);
      return;
    }
    yield put(
      openSnackbar({
        open: true,
        message: response.data.message,
        variant: 'alert',
        alert: {
          color: 'success'
        },
        close: false
      })
    );
    yield call(action?.payload?.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* getEditRequest(action: { payload: string }) {
  yield delay(500);

  try {
    const response: AxiosResponse = yield call(GetProductNewEditRequest, action.payload);
    if (response.data.status == false) {
      return;
    }
    yield put(actions.ProductEdit(response.data.data[0]));
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* authRepoSagaProduct() {
  yield takeLatest(actions.NewProductAdded, ProductAddRequest);
  yield takeLatest(actions.doGetProductList, getProductListRequest);
  yield takeLatest(actions.ProductDelete, deleteAnnouncement);
  yield takeLatest(actions.addImage, FileUploadRequest);
  yield takeLatest(actions.dogetDataForEdit, getEditRequest);
  yield takeLatest(actions.UpdatedProductAdded, CategoryUpdatedRequest);
}

// questionAddRequestMain
