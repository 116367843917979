import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../newTypes/RootState';
import { initialState } from './slice';

const selectDomain = (state: RootState) => {
  if (state && state.ReportsState) {
    return state.ReportsState;
  } else {
    return initialState;
  }
};
export const SelectLoading = createSelector([selectDomain], (state) => state.loading);
export const SelectPageNo = createSelector([selectDomain], (state) => state.pageNo);
export const SelectPageSize = createSelector([selectDomain], (state) => state.pageSize);
export const SelectTotalRow = createSelector([selectDomain], (state) => state.totalRow);
export const SelectSearch = createSelector([selectDomain], (state) => state.search);
export const selectOrderList = createSelector([selectDomain], (state) => state.List);
export const SelectTeacherLoading = createSelector([selectDomain], (state) => state.teacherloading);
export const SelectTeacherPageNo = createSelector([selectDomain], (state) => state.teacherpageNo);
export const SelectTeacherPageSize = createSelector([selectDomain], (state) => state.teacherpageSize);
export const SelectTeacherTotalRow = createSelector([selectDomain], (state) => state.teachertotalRow);
export const SelectTeacherSearch = createSelector([selectDomain], (state) => state.teachersearch);
export const selectTeachertList = createSelector([selectDomain], (state) => state.TeacherList);
export const SelectfilterteacherList = createSelector([selectDomain], (state) => state.filterteacherList);
export const selectfilterclassList = createSelector([selectDomain], (state) => state.filterclassList);
export const selectReportPageFilter = createSelector([selectDomain], (state) => state.filter);
