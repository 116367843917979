export interface FilterInterface {
  title: string;
  id: string;
  status: number;
  items: FilterItem[];
  itemTitle: string;
}
export interface FilterItem {
  title: string;
}
export enum StudentType {
  MY_STUDENT = 1,
  ALL_STUDENT = 0
}
export interface StudentAddInterface {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  mobileNumber: string;
  address: string;
  zip: number;
  city: string;
  state: string;
  coins: number;
  password: string;
  section: string;
  class: string;
  coinsEarned: number;
  coinsUsed: number;
  teacherFirstName: string;
  teacherLastName: string;
  addCoins: number;
  comment: string;
}
export interface StudentDetailsInterface {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  mobileNumber: number;
  class: string;
  teacherFirstName: string;
  teacherLastName: string;
  coinsEarned: number;
  coinsUsed: number;
  dateOfUsed: string;
  productPurchased: string;
  comments: string;
  createdAt: string;
  coins: any;
}
export interface StudentAddCoinInterface {
  id: string;
  coins: number;
  comment: string;
}
export interface StudentState {
  List: FilterInterface[];
  Form: FilterInterface;
  studentAddList: StudentAddInterface[];
  studentAddForm: StudentAddInterface;
  studentAddCoinsForm: StudentAddCoinInterface;
  StudentDetails: StudentDetailsInterface[];
  editIndex: number;
  loading: boolean;
  isadd: boolean;
  pageNo: number;
  pageSize: number;
  totalRow: number;
  detailsPageNo: number;
  detailsPageSize: number;
  detailsTotalRow: number;
  search: string;
  activeList: FilterInterface[];
  studenttype: StudentType;
}

export type InitialDiscountStata = StudentState;
