import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '../../../utils copy/@reduxjs/toolkit';
import { CategoryNewState, CategoryInterface } from '../types';
import { set } from 'lodash';

export const initialState: CategoryNewState = {
  loading: false,
  pageSize: 10,
  totalRow: 0,
  search: '',
  pageNo: 1,
  CategoryFormData: {
    id: '',
    title: ''
  },
  CategoryList: [],
  editIndex: 0,
  isadd: false,
  dissableCheck: false
};

export const useClinicSlice = createSlice({
  name: 'CategoryNewState',
  initialState,
  reducers: {
    updateNewCategoryFormValue: (state, action: PayloadAction<{ key: string; value: any }>) => {
      set(state, `CategoryFormData.${action.payload.key}`, action.payload.value);
    },
    NewCategoryAdded: (state, action: PayloadAction<{ callback: () => void }>) => {
      console.log('Yo Reached at UserAdded');
      // state.FaqList.push(state.FaqFormData);
    },
    dogetDataForEdit: (state, action: PayloadAction<string>) => {},
    UpdatedCategoryAdded: (state, action: PayloadAction<{ callback: () => void }>) => {
      console.log('Yo Reached at UserAdded');
      // state.FaqList.push(state.FaqFormData);
    },
    doGetCategoryList: (state) => {},
    clearCategoryFrom: (state) => {
      state.CategoryFormData.id= "";
      state.CategoryFormData.title= ""
    },
    setCategoryList: (state, action: PayloadAction<Array<CategoryInterface>>) => {
      state.CategoryList = action.payload;
    },
    setCategoryForm: (state, action: PayloadAction<CategoryInterface>) => {
      state.CategoryFormData = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      console.log('Loading');
      state.loading = action.payload;
    },
    setTotalRow: (state, action: PayloadAction<number>) => {
      state.totalRow = action.payload;
    },

    setPageNo: (state, action: PayloadAction<number>) => {
      state.pageNo = action.payload;
    },
    setPageSize: (state, action: PayloadAction<number>) => {
      console.log('pageSize');
      state.pageSize = action.payload;
    },
    CategoryDelete: (state, action: PayloadAction<any>) => {}
  }
});

export const { reducer, actions, name: sliceKey } = useClinicSlice;
