import { call, delay, put, select, takeLatest } from 'redux-saga/effects';
// import { selectLoginForm } from './selector';
import { actions } from './slice';
import { openSnackbar } from 'store/reducers/snackbar';
import { AxiosResponse } from 'axios';
import { SelectFrom, SelectPageNo, SelectPageSize, SelectSearch } from './selector';

import { GetDiscountEditRequest, GetDiscountlistRequest, SaveDiscountRequest, UpdateDiscountRequest } from 'utils copy/request';
import { DiscountInterface } from '../types';
import moment from 'moment';
import CatchBlockFunction from 'hooks/CatchBlockFunction';

export function* getList() {
  yield delay(500);

  try {
    const pageNo: number = yield select(SelectPageNo);
    const pageSize: number = yield select(SelectPageSize);
    const searchText: string = yield select(SelectSearch);
    const response: AxiosResponse = yield call(
      GetDiscountlistRequest,
      `?pageNo=${pageNo}&pageSize=${pageSize}${searchText.length > 0 ? '&search=' + searchText : ''}`
    );
    yield put(actions.setLoading(false));
    if (response.data.status == false) {
      // toast.error(response.data.message);
      return;
    }
    yield put(actions.setTotalRow(response.data.data.meta ? response.data.data.meta.total : 0));
    yield put(actions.setList(response.data.data.results));
  } catch (error: any) {
   CatchBlockFunction(error)
  }
}

export function* AddRequest(action: { payload: { callback: any } }) {
  yield delay(500);

  const form: DiscountInterface = yield select(SelectFrom);
  if (form.title.length === 0) {
    yield put(openSnackbar({
      open: true,
      message: 'please fill title',
      variant: 'alert',
      alert: {
        color: 'error'
      },
      close: false
    }));

    return;
  }
  if (form.code.length === 0) {
    yield put(openSnackbar({
      open: true,
      message: 'please fill code',
      variant: 'alert',
      alert: {
        color: 'error'
      },
      close: false
    }));

    return;
  }
  if (form.amount == 0) {
    yield put(openSnackbar({
      open: true,
      message: 'please fill amount grater than 0',
      variant: 'alert',
      alert: {
        color: 'error'
      },
      close: false
    }));

    return;
  }
  if (form.start_date.length === 0) {
    yield put(openSnackbar({
      open: true,
      message: 'please fill start date',
      variant: 'alert',
      alert: {
        color: 'error'
      },
      close: false
    }));

    return;
  }
  if (form.end_date.length === 0) {
    yield put(openSnackbar({
      open: true,
      message: 'please fill end date',
      variant: 'alert',
      alert: {
        color: 'error'
      },
      close: false
    }));

    return;
  }
  if (moment(form.start_date) > moment(form.end_date)) {
    yield put( openSnackbar({
      open: true,
      message: 'please enter end date grater than start date',
      variant: 'alert',
      alert: {
        color: 'error'
      },
      close: false
    }));

    return;
  }

  console.log('saga working');
  let data = { ...form };

  try {
    const response: AxiosResponse = yield call(SaveDiscountRequest, data);
    if (response.data.status == false) {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: false
        })
      );

      return;
    }
    yield put(
      openSnackbar({
        open: true,
        message: response.data.message,
        variant: 'alert',
        alert: {
          color: 'success'
        },
        close: false
      })
    );

    yield call(action?.payload?.callback);
  } catch (error: any) {
      CatchBlockFunction(error)
  }
}
export function* getEditRequest(action: { payload: string }) {
  yield delay(500);

  try {
    const response: AxiosResponse = yield call(GetDiscountEditRequest, action.payload);
    if (response.data.status == false) {
      // toast.error(response.data.message);
      return;
    }

    yield put(actions.setUserGroupEdit(response.data.data));
    // yield put(actions.setSelectedUserEditList(response.data.user));
  } catch (error: any) {
      CatchBlockFunction(error)
  }
}
export function* UpdateRequest(action: { payload: { callback: any } }) {
  yield delay(500);

  const form: DiscountInterface = yield select(SelectFrom);

  if (form.title.length === 0) {
    yield put(openSnackbar({
      open: true,
      message: 'please fill title',
      variant: 'alert',
      alert: {
        color: 'error'
      },
      close: false
    }));

    return;
  }
  if (form.code.length === 0) {
    yield put(openSnackbar({
      open: true,
      message: 'please fill code',
      variant: 'alert',
      alert: {
        color: 'error'
      },
      close: false
    }));

    return;
  }
  if (form.amount == 0) {
    yield put(openSnackbar({
      open: true,
      message: 'please fill amount grater than 0',
      variant: 'alert',
      alert: {
        color: 'error'
      },
      close: false
    }));

    return;
  }
  if (form.start_date.length === 0) {
    yield put(openSnackbar({
      open: true,
      message: 'please fill start date',
      variant: 'alert',
      alert: {
        color: 'error'
      },
      close: false
    }));

    return;
  }
  if (form.end_date.length === 0) {
    yield put(openSnackbar({
      open: true,
      message: 'please fill end date',
      variant: 'alert',
      alert: {
        color: 'error'
      },
      close: false
    }));

    return;
  }
  if (moment(form.start_date) > moment(form.end_date)) {
    yield put( openSnackbar({
      open: true,
      message: 'please enter end date grater than start date',
      variant: 'alert',
      alert: {
        color: 'error'
      },
      close: false
    }));

    return;
  }

  console.log('saga working');
  let data = { ...form };
  try {
    const response: AxiosResponse = yield call(UpdateDiscountRequest, data);
    if (response.data.status == false) {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: false
        })
      );

      return;
    }
    yield put(
      openSnackbar({
        open: true,
        message: response.data.message,
        variant: 'alert',
        alert: {
          color: 'success'
        },
        close: false
      })
    );

    yield call(action?.payload?.callback);
  } catch (error: any) {
      CatchBlockFunction(error)
  }
}

export function* discountRepoSaga() {
  yield takeLatest(actions.doAdd, AddRequest);
  yield takeLatest(actions.doGetList, getList);
  yield takeLatest(actions.getDataForEdit, getEditRequest);
  yield takeLatest(actions.doUpdate, UpdateRequest);
}
