import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../newTypes/RootState';

import { initialState } from './slice';

const selectDomain = (state: RootState) => {
  if (state) {
    return state.teacherState;
  } else {
    return initialState;
  }
};
export const selectList = createSelector([selectDomain], (state) => state.List);
export const SelectLoading = createSelector([selectDomain], (state) => state.loading);
export const SelectPageNo = createSelector([selectDomain], (state) => state.pageNo);
export const SelectPageSize = createSelector([selectDomain], (state) => state.pageSize);
export const SelectTotalRow = createSelector([selectDomain], (state) => state.totalRow);
export const SelectSearch = createSelector([selectDomain], (state) => state.search);
export const SelectFrom = createSelector([selectDomain], (state) => state.Form);
export const SelectParentList = createSelector([selectDomain], (state) => state.parentList);
export const SelectChildtList = createSelector([selectDomain], (state) => state.childList);
export const selectTeachertList = createSelector([selectDomain], (state) => state.TeacherList);
export const SelectTeacherAddForm = createSelector([selectDomain], (state) => state.teacherAddForm);
export const SelectTeacherAddList = createSelector([selectDomain], (state) => state.teacherAddList);
export const SelectTeacherLoading = createSelector([selectDomain], (state) => state.teacherloading);
export const SelectTeacherPageNo = createSelector([selectDomain], (state) => state.teacherpageNo);
export const SelectTeacherPageSize = createSelector([selectDomain], (state) => state.teacherpageSize);
export const SelectTeacherTotalRow = createSelector([selectDomain], (state) => state.teachertotalRow);
export const SelectTeacherSearch = createSelector([selectDomain], (state) => state.teachersearch);
export const SelectTeacherDetailLoading = createSelector([selectDomain], (state) => state.teacherDetailloading);
export const SelectTeacherDetailPageNo = createSelector([selectDomain], (state) => state.teacherDetailpageNo);
export const SelectTeacherDetailPageSize = createSelector([selectDomain], (state) => state.teacherDetailpageSize);
export const SelectTeacherDetailTotalRow = createSelector([selectDomain], (state) => state.teacherDetailtotalRow);
export const SelectTeacherDetailSearch = createSelector([selectDomain], (state) => state.teacherDetailsearch);
export const selectTeacherDetailList = createSelector([selectDomain], (state) => state.TeacherDetailList);
export const selectStudentAddCoinsForm = createSelector([selectDomain], (state) => state.studentAddCoinsForm);

export const SelectStudentPageNo = createSelector([selectDomain], (state) => state.StudentDetailpageNo);
export const SelectStudentPageSize = createSelector([selectDomain], (state) => state.StudentDetailpageSize);
export const SelectStudentTotalRow = createSelector([selectDomain], (state) => state.StudentDetailtotalRow);
export const selectStudentList = createSelector([selectDomain], (state) => state.StudentDetailList);


export const SelectCoinStudentPageNo = createSelector([selectDomain], (state) => state.CoinStudentDetailpageNo);
export const SelectCoinStudentPageSize = createSelector([selectDomain], (state) => state.CoinStudentDetailpageSize);
export const SelectCoinStudentTotalRow = createSelector([selectDomain], (state) => state.CoinStudentDetailtotalRow);
export const selectCoinStudentList = createSelector([selectDomain], (state) => state.CoinStudentDetailList);