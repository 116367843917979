import { call, delay, put, select, takeLatest } from 'redux-saga/effects';
// import { selectLoginForm } from './selector';
import { actions } from './slice';
import { openSnackbar } from 'store/reducers/snackbar';
import { AxiosResponse } from 'axios';
import { SelectFrom, SelectPageNo, SelectPageSize, SelectSearch } from './selector';

import {
  GetSubscriptionEditRequest,
  GetSubscriptionlistRequest,
  SaveSubscriptionRequest,
  UpdateSubscriptionRequest
} from 'utils copy/request';
import { SubscriptionInterface } from '../types';
import CatchBlockFunction from 'hooks/CatchBlockFunction';

export function* getList() {
  yield delay(500);

  try {
    const pageNo: number = yield select(SelectPageNo);
    const pageSize: number = yield select(SelectPageSize);
    const searchText: string = yield select(SelectSearch);
    const response: AxiosResponse = yield call(
      GetSubscriptionlistRequest,
      `?pageNo=${pageNo}&pageSize=${pageSize}${searchText.length > 0 ? '&search=' + searchText : ''}`
    );
    yield put(actions.setLoading(false));
    if (response.data.status == false) {
      // toast.error(response.data.message);
      return;
    }
    yield put(actions.setTotalRow(response.data.data.meta ? response.data.data.meta.total : 0));
    yield put(actions.setList(response.data.data.results));
  } catch (error: any) {
   CatchBlockFunction(error)
  }
}

export function* AddRequest(action: { payload: { callback: any } }) {
  yield delay(500);

  const form: SubscriptionInterface = yield select(SelectFrom);

  if (form.title.length === 0) {
    yield put(
      openSnackbar({
        open: true,
        message: 'please fill title',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: false
      })
    );

    return;
  }

  if (form.description.length === 0) {
    yield put(
      openSnackbar({
        open: true,
        message: 'please fill description',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: false
      })
    );

    return;
  }

  if (form.amount == 0) {
    yield put(
      openSnackbar({
        open: true,
        message: 'please fill amount grater than 0',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: false
      })
    );

    return;
  }

  if (form.items.length === 0) {
    yield put(
      openSnackbar({
        open: true,
        message: 'please add atleast one item',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: false
      })
    );

    return;
  }

  console.log('saga working');
  let data = { ...form };

  try {
    const response: AxiosResponse = yield call(SaveSubscriptionRequest, data);
    if (response.data.status == false) {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: false
        })
      );

      return;
    }
    yield put(
      openSnackbar({
        open: true,
        message: response.data.message,
        variant: 'alert',
        alert: {
          color: 'success'
        },
        close: false
      })
    );

    yield call(action?.payload?.callback);
  } catch (error: any) {
      CatchBlockFunction(error)
  }
}
export function* getGroupEditRequest(action: { payload: string }) {
  yield delay(500);

  try {
    const response: AxiosResponse = yield call(GetSubscriptionEditRequest, action.payload);
    if (response.data.status == false) {
      // toast.error(response.data.message);
      return;
    }

    yield put(actions.setUserGroupEdit(response.data.data));
    // yield put(actions.setSelectedUserEditList(response.data.user));
  } catch (error: any) {
      CatchBlockFunction(error)
  }
}
export function* groupUpdateRequest(action: { payload: { callback: any } }) {
  yield delay(500);

  const form: SubscriptionInterface = yield select(SelectFrom);

  if (form.title.length === 0) {
    yield put(
      openSnackbar({
        open: true,
        message: 'please fill title',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: false
      })
    );

    return;
  }

  if (form.description.length === 0) {
    yield put(
      openSnackbar({
        open: true,
        message: 'please fill description',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: false
      })
    );

    return;
  }

  if (form.amount == 0) {
    yield put(
      openSnackbar({
        open: true,
        message: 'please fill amount grater than 0',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: false
      })
    );

    return;
  }

  if (form.items.length === 0) {
    yield put(
      openSnackbar({
        open: true,
        message: 'please add atleast one item',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: false
      })
    );

    return;
  }

  console.log('saga working');
  let data = { ...form };
  try {
    const response: AxiosResponse = yield call(UpdateSubscriptionRequest, data);
    if (response.data.status == false) {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: false
        })
      );

      return;
    }
    yield put(
      openSnackbar({
        open: true,
        message: response.data.message,
        variant: 'alert',
        alert: {
          color: 'success'
        },
        close: false
      })
    );

    yield call(action?.payload?.callback);
  } catch (error: any) {
      CatchBlockFunction(error)
  }
}

export function* subscriptionRepoSaga() {
  yield takeLatest(actions.doAdd, AddRequest);
  yield takeLatest(actions.doGetList, getList);
  yield takeLatest(actions.getDataForEdit, getGroupEditRequest);
  yield takeLatest(actions.doUpdate, groupUpdateRequest);
}
