// project import
import Routes from './routes';
import ThemeCustomization from './themes';
import Locales from './components/Locales';
import RTLLayout from './components/RTLLayout';
import ScrollTop from './components/ScrollTop';
import Snackbar from './components/@extended/Snackbar';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import { FirebaseProvider as AuthProvider } from './contexts/FirebaseContext';
import { sliceKey as MenusliceKey, reducer as menuReducer } from './store/reducers/menu';
import { sliceKey as SnackbarsliceKey, reducer as SnackbarReducer } from './store/reducers/snackbar';
import { sliceKey as cataloguesliceKey, reducer as catalogueReducer } from './pages/Catalouge/redux/slice';
import { sliceKey as usersliceKey, reducer as userReducer } from './pages/UserMain/redux/slice';
import { sliceKey as AuthsliceKey, reducer as AuthReducer } from './sections/auth/redux/slice';
import { sliceKey as RevenuesliceKey, reducer as RevenueReducer } from './pages/Revenue/redux/slice';
import { sliceKey as discountsliceKey, reducer as discountReducer } from './pages/Discount/redux/slice';
import { authRepoSaga } from './sections/auth/redux/saga';
import { userRepoSaga } from './pages/UserMain/redux/saga';
import { catelogRepoSaga } from './pages/Catalouge/redux/saga';
import { userGroupRepoSaga } from './pages/user-group/redux/saga';
import { discountRepoSaga } from './pages/Discount/redux/saga';
import { subscriptionRepoSaga } from './pages/Subscription/redux/saga';
import { sliceKey as userGroupsliceKey, reducer as userGroupReducer } from './pages/user-group/redux/slice';
import { sliceKey as subscriptionsliceKey, reducer as subscriptionReducer } from './pages/Subscription/redux/slice';
import { subscriptionReportRepoSaga } from './pages/SubscriptionReport/redux/saga';
import { sliceKey as subscriptionReportSliceKey, reducer as subscriptionReportReducer } from './pages/SubscriptionReport/redux/slice';
import { discountReportRepoSaga } from './pages/DiscountReport/redux/saga';
import { sliceKey as discountReportSliceKey, reducer as discountReportReducer } from './pages/DiscountReport/redux/slice';

import { sliceKey as storyReportSliceKey, reducer as storyReportReducer } from './pages/StoryReport/redux/slice';
import { filterRepoSaga } from 'pages/Filter/redux/saga';
import { sliceKey as filterSliceKey, reducer as filterReducer } from 'pages/Filter/redux/slice';
import { storyReportRepoSaga } from 'pages/StoryReport/redux/saga';
import { CategoryRepoSaga } from 'pages/Category/redux/saga';
import { sliceKey as CategorySliceKey, reducer as CategoryReducer } from 'pages/Category/redux/slice';
import { authRepoSagaProduct } from 'pages/Product/redux/saga';
import { sliceKey as ProductSliceKey, reducer as ProductReducer } from 'pages/Product/redux/slice';
import { authRepoSagaCategory } from 'pages/CategoryNew/redux/saga';
import { sliceKey as CategoryNewSliceKey, reducer as CategoryNewReducer } from 'pages/CategoryNew/redux/slice';

//******Product Cart Section******//
import { sliceKey as ProductCartSliceKey, reducer as ProductCartReducer } from 'pages/userproduct/redux/slice';
import { ProductCartRepoSaga } from 'pages/userproduct/redux/saga';
//******Order List******//
import { sliceKey as OrderSliceKey, reducer as OrderReducer } from 'pages/orderList/redux/slice';
import { OrderRepoSaga } from 'pages/orderList/redux/saga';
import { ReportsStateRepoSaga } from 'pages/Report/redux/saga';
import { sliceKey as ReportsSliceKey, reducer as ReportsReducer } from 'pages/Report/redux/slice';
// ==============================|| APP - THEME, ROUTER, LOCAL  ||============================== //
import { sliceKey as DashboardSliceKey, reducer as DashboardReducer } from "pages/dashboard/redux/slice";
import { authRepoSagaDashboard } from 'pages/dashboard/redux/saga';
import { sliceKey as RoleUsersliceKey, reducer as RoleUserReducer } from 'sections/apps/profiles/account/redux/slice';
import { authRepoSagaRoleUser } from 'sections/apps/profiles/account/redux/saga';
const App = () => {
  useInjectReducer({ key: SnackbarsliceKey, reducer: SnackbarReducer });
  useInjectReducer({ key: MenusliceKey, reducer: menuReducer });
  useInjectReducer({ key: cataloguesliceKey, reducer: catalogueReducer });
  useInjectSaga({ key: cataloguesliceKey, saga: catelogRepoSaga });
  useInjectReducer({ key: AuthsliceKey, reducer: AuthReducer });
  useInjectSaga({ key: AuthsliceKey, saga: authRepoSaga });
  useInjectReducer({ key: usersliceKey, reducer: userReducer });
  useInjectSaga({ key: usersliceKey, saga: userRepoSaga });
  useInjectReducer({ key: RevenuesliceKey, reducer: RevenueReducer });
  useInjectSaga({ key: userGroupsliceKey, saga: userGroupRepoSaga });
  useInjectReducer({ key: userGroupsliceKey, reducer: userGroupReducer });
  useInjectSaga({ key: discountsliceKey, saga: discountRepoSaga });
  useInjectReducer({ key: discountsliceKey, reducer: discountReducer });
  useInjectSaga({ key: subscriptionsliceKey, saga: subscriptionRepoSaga });
  useInjectReducer({ key: subscriptionsliceKey, reducer: subscriptionReducer });
  useInjectSaga({ key: subscriptionReportSliceKey, saga: subscriptionReportRepoSaga });
  useInjectReducer({ key: subscriptionReportSliceKey, reducer: subscriptionReportReducer });
  useInjectSaga({ key: discountReportSliceKey, saga: discountReportRepoSaga });
  useInjectReducer({ key: discountReportSliceKey, reducer: discountReportReducer });
  useInjectSaga({ key: storyReportSliceKey, saga: storyReportRepoSaga });
  useInjectReducer({ key: storyReportSliceKey, reducer: storyReportReducer });
  useInjectSaga({ key: filterSliceKey, saga: filterRepoSaga });
  useInjectReducer({ key: filterSliceKey, reducer: filterReducer });
  useInjectSaga({ key: CategorySliceKey, saga: CategoryRepoSaga });
  useInjectReducer({ key: CategorySliceKey, reducer: CategoryReducer });
  useInjectSaga({ key: ProductSliceKey, saga: authRepoSagaProduct });
  useInjectReducer({ key: ProductSliceKey, reducer: ProductReducer });
  useInjectSaga({ key: CategoryNewSliceKey, saga: authRepoSagaCategory });
  useInjectReducer({ key: CategoryNewSliceKey, reducer: CategoryNewReducer });
  //******Product Cart Section******//
  useInjectSaga({ key: ProductCartSliceKey, saga: ProductCartRepoSaga });
  useInjectReducer({ key: ProductCartSliceKey, reducer: ProductCartReducer });
  //******Order List Section******//
  useInjectSaga({ key: OrderSliceKey, saga: OrderRepoSaga });
  useInjectReducer({ key: OrderSliceKey, reducer: OrderReducer });
  useInjectReducer({ key: DashboardSliceKey, reducer: DashboardReducer });
useInjectSaga({ key: DashboardSliceKey, saga: authRepoSagaDashboard });

  useInjectSaga({ key: ReportsSliceKey, saga: ReportsStateRepoSaga });
  useInjectReducer({ key: ReportsSliceKey, reducer: ReportsReducer });
  useInjectReducer({ key: RoleUsersliceKey, reducer: RoleUserReducer });
  useInjectSaga({ key: RoleUsersliceKey, saga: authRepoSagaRoleUser }); //dashboard

  return (
    <ThemeCustomization>
      <RTLLayout>
        <Locales>
          <ScrollTop>
            <AuthProvider>
              <>
                <Routes />
                <Snackbar />
              </>
            </AuthProvider>
          </ScrollTop>
        </Locales>
      </RTLLayout>
    </ThemeCustomization>
  );
};

export default App;
